/**
 * Component Namespace: App/PanelMain/PanelSubmit
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Redirect, withRouter, Link } from 'react-router-dom'

/*stores*/
import ShirtStore from '../../../stores/ShirtStore'
import QuoteStore from '../../../stores/QuoteStore'
import CategoryStore from '../../../stores/CategoryStore'

/*components*/
import SectionCartGrid from './SectionCartGrid'
import SectionQuoteNotes from './SectionQuoteNotes'

/*actions*/
import QuoteActionCreators from '../../../actions/QuoteActionCreators'

/*utils*/
import QuoteUtilities from '../../../utils/QuoteUtilities'

/*libraries*/
import classnames from 'classnames'


class PanelSubmit extends React.Component {

	/*app functions*/
	constructor() {
		super();
		this.state = {
			cartQuotes: [],
			cartTotals: {},
			addOns: [],
			notes: '',
			customer_quote_title: '',
			customer_name: '',
			customer_email: '',
			customer_message: '',
			panelClass: ''
		}
	}

	componentDidMount = () => {

		window.scrollTo(0, 0);
		
		QuoteStore.addChangeListener(this._onChange);

		var cartQuotes = QuoteStore.getCartQuotes();
		var cartTotals = QuoteStore.getCartTotals();
		var quoteFieldValues = QuoteStore.getSubmitFormFieldValues();

		this.setState( {
			cartQuotes: cartQuotes,
			cartTotals: cartTotals,
			addOns: [],
			notes: '',
		});

		QuoteActionCreators.addQuoteToCart();

	}

	componentWillUnmount = () => {
		QuoteStore.removeChangeListener(this._onChange);
	}

	_onChange = ()  => {
		
		var cartQuotes = QuoteStore.getCartQuotes();
		var cartTotals = QuoteStore.getCartTotals();
		var quoteFieldValues = QuoteStore.getSubmitFormFieldValues();

		this.setState({ 
			cartQuotes: cartQuotes,
			cartTotals: cartTotals,
			customer_quote_title: quoteFieldValues.customer_quote_title,
			customer_name: quoteFieldValues.customer_name,
			customer_email: quoteFieldValues.customer_email,
			customer_message: quoteFieldValues.customer_message
		});

	}

	handleAddAnotherShirt = (e)  => {
		e.preventDefault();

		QuoteActionCreators.setQuoteTemplate();
		this.handleQuoteDefaultValues();
		
		setTimeout(() => {
			this.props.history.push({
				pathname: '/',
				search: "?quantity=200"
			});
		}, 1000);
	}

	handleQuoteDefaultValues = () =>  {
		var defaultQuoteValues = {
			selectedShirt: null,
			quantity: 200
		}

		QuoteActionCreators.setQuoteDefaultValues(defaultQuoteValues);
	}

	hanldeEditShirtQuote = (unique_id, shirtTotalQuantity, index, e)  => {
		e.preventDefault();
		
		this.setState({panelClass: 'transitioning'});
		QuoteActionCreators.addQuoteToActiveQuote(unique_id);

		setTimeout(() => {
			this.props.history.push({
				pathname: '/customize',
				search: '?quantity=' + shirtTotalQuantity
			});
		}, 500);		

	}

	handleRemoveShirtQuote = (unique_id, e)  => {
		e.preventDefault();
		QuoteActionCreators.removeShirtQuote(unique_id);
	}

	handleUploadSuccess = (object)  => {
		
		AppDispatcher.dispatch({
			actionType: AppConstants.CREATE_IMAGE,
			data: object
		});

		this.setState({uploading: false});

	}

	handleSubmitForm = (formData)  => {
		
		var submitData = {
			customer_quote_title: this.state.customer_quote_title,
			customer_name: this.state.customer_name,
			customer_email: this.state.customer_email,
			customer_message: this.state.customer_message,
			cartQuotes: this.state.cartQuotes,
			cartTotals: this.state.cartTotals,
		}

		QuoteActionCreators.submitQuote(submitData);

		this.setState({panelClass: 'transitioning'});
		
		setTimeout(() => {
			this.props.history.push({
				pathname: '/confirm'
			});
		}, 600);

	}

	handleFieldChange = (fieldKey, fieldValue)  => {
		var data = {
			field_key: fieldKey,
			value: fieldValue
		};
		QuoteActionCreators.updateSubmitFormFieldValue(data);
	}

	setCartActiveQuote = (unique_id) => {
		QuoteActionCreators.selectCartQuotePreview(unique_id);
	}

	render = () => {

		var appPanelClasses = classnames(
			this.state.panelClass,
			'app-panel',
			'app-panel-submit',
			'panel-section-submit',
		);

		return (
			
			<div className={appPanelClasses}>

				<header className="panel-header">
				</header>

				<SectionCartGrid
					cartQuotes={this.state.cartQuotes}
					cartTotals={this.state.cartTotals}
					handleRemoveShirtQuote={this.handleRemoveShirtQuote}
					hanldeEditShirtQuote={this.hanldeEditShirtQuote}
					setCartActiveQuote={this.setCartActiveQuote}
				/>
				
				<div className="next-panel to-submit">
					<div className="next-panel-inner">
						<a className="button button--next-panel button--purple" onClick={this.handleAddAnotherShirt}>Add Another Shirt!</a>
					</div>
				</div>

				<SectionQuoteNotes
					handleFieldChange={this.handleFieldChange}
					handleUploadSuccess={this.handleUploadSuccess}
					submitForm={this.handleSubmitForm}
					customer_quote_title = {this.state.customer_quote_title}
					customer_name = {this.state.customer_name}
					customer_email = {this.state.customer_email}
					customer_message = {this.state.customer_message}
				/>

			</div>
			
		)
	}
}
export default withRouter(PanelSubmit);
/**
 * Component Namespace: App/PanelMain/PanelSelectShirt
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Redirect, withRouter } from 'react-router-dom'

/*stores*/
import ShirtStore from '../../../stores/ShirtStore'

/*components*/
import ShirtFilterGrid from './ShirtFilterGrid'
import ShirtGrid from './ShirtGrid'

/*actions*/
import QuoteActionCreators from '../../../actions/QuoteActionCreators'
import ShirtActionCreators from '../../../actions/ShirtActionCreators'

/*stores*/
import QuoteStore from '../../../stores/QuoteStore'
import CategoryStore from '../../../stores/CategoryStore'

/*utilities*/
import QuoteUtilities from '../../../utils/QuoteUtilities'

/*libraries*/
import queryString from 'queryString'
import classnames from 'classnames'
import update from 'immutability-helper'

class PanelSelectShirt extends React.Component {

	/*app functions*/
	constructor() {
		super();
		this.state = {
			categories: [],
			panelClass: '',
			quantity: 0,
			selectedCategory: '',
			selectedShirtPosition: {},
			shirts: null,
		}
	}

	componentDidMount = () => {

		this.setState( {
			shirts: QuoteStore.getAdjustedShirts(),
			activeQuote: QuoteStore.getActiveQuote(),
			categories: CategoryStore.getAllCategories(),
		});

		CategoryStore.addChangeListener(this._onCategoryWebChange);
		QuoteStore.addChangeListener(this._onQuoteStoreChange);

		this.handleSelectCategory(0);

	}

	componentWillUnmount = () => {
		
		CategoryStore.removeChangeListener(this._onCategoryWebChange);
		QuoteStore.removeChangeListener(this._onQuoteStoreChange);

	}

	_onQuoteStoreChange = () =>  {
		this.setState( {
			shirts: QuoteStore.getAdjustedShirts(),
			activeQuote: QuoteStore.getActiveQuote(),
		});
	}

	_onCategoryWebChange = () =>  {
		this.setState( {
			categories: CategoryStore.getAllCategories(),
		});
	}

	getElOffset = (el) => {
		var rect = el.getBoundingClientRect(),
		scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
		scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
	}

	/*app actions*/
	handleSelectShirt = (animationDuration, unique_id) => {

		var timeoutDelay = ( animationDuration * 2000 ) + 500;

		var jsObject = document.getElementById(unique_id);
		var rectObject = jsObject.getBoundingClientRect();

		this.setState({
			selectedShirtPosition: rectObject
		});

		QuoteActionCreators.selectShirt(unique_id);

		setTimeout(() => {
			this.props.history.push({
				pathname: '/customize'
			});
			QuoteActionCreators.selectShirt(unique_id);
		}, timeoutDelay);

	}

	handleSelectCategory = (unique_id) => {
		this.setState({selectedCategory: unique_id});
		ShirtActionCreators.filterShirts(unique_id);
	}

	handleNextPanel = (e) => {
		e.preventDefault();
		hashHistory.push('/customize');
	}

	render = () => {

		var appPanelClasses = classnames(
			this.state.panelClass,
			'app-panel',
			'app-panel-select-shirt',
			'panel-select-shirt',
		);

		if( !this.state.shirts ) {
			return <div>h3</div>
		}

		return (
			
			<div className={appPanelClasses}>
				
				<header className="panel-header">
					<div className="inner-container panel-header__inner">
					
						<h2 className="panel-header__title">Select Your Product</h2>

					</div>
				</header>

				<ShirtFilterGrid
					categories={this.state.categories}
					filterShirts={this.handleSelectCategory}
					selectedCategory={this.state.selectedCategory}
				/>
				<ShirtGrid
					shirts={this.state.shirts}
					activeQuote={this.state.activeQuote}
					selectShirt={this.handleSelectShirt}
					selectedCategory={this.state.selectedCategory}
					selectedShirtPosition={this.state.selectedShirtPosition}
				/>
				
			</div>
			
		)
	}
}
export default withRouter(PanelSelectShirt);




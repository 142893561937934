/**
 * Component Namespace: App/PanelMain/PanelArtwork
 */

 import React from 'react'
 import { render } from 'react-dom'
 import ReactDOMServer from 'react-dom/server'
 import { Router, Route, hashHistory } from 'react-router'

 // import DropzoneComponent from 'react-dropzone-component'
import Dropzone from 'react-dropzone';
import request from 'superagent/lib/client';
import {post} from 'axios';

import Loader from '../loader';
import CloseBtn from '../close-button';

 /*stores*/
 import ImageStore from '../../stores/ImageStore'
 import QuoteStore from '../../stores/QuoteStore'

 /*libraries*/
 import Modal from 'react-modal'
 import classnames from 'classnames'
 import update from 'immutability-helper'

 /*actions*/
 import QuoteActionCreators from '../../actions/QuoteActionCreators'

 /*utils*/
 import AppHelpers from '../../utils/AppHelpers'
 import AppUtilities from '../../utils/AppUtilities'


 export default class ArtworkUpload extends React.Component {

	constructor() {
		super();
		this.state = {
			images: {
				front: [],
				back: []
			},
            progress : 0,
            uploading : false,
            supported_mime : [
                'image/jpeg',
                'image/png',
                'image/vnd.adobe.photoshop',
                'application/postscript'
            ]
		}
	}
	onDropFront = (data) => {
		this.handleSingleImageUpload(data);
	}

	onDropBack = (data) => {
		this.handleSingleImageUpload(data);
	}

	handleUploadSuccess = (data) => {

		var object = JSON.parse(data.xhr.response);
		this.props.handleUploadSuccess(object);

	}


	removeDroppedFile(name, e){
		e.preventDefault();
		this.setState({
			images : this.state.images.filter((image) => {
				return image.name !== name
			})
		})
	}

	uploadFiles = (side) => {

		let images = this.state.images[side],
		total_files = this.state.images[side].length,
		uploaded = 0;

		console.log('images', images);

		this.setState({
			uploading : true
		});

		images.map((image) => {

			let formData = new FormData();
			
			var d = new Date();
			var upload_timestamp = d.getTime();
			var imageData = AppUtilities.formatCreatedImage(image.name, upload_timestamp, null, 2);
			imageData.side = side;

			var config = { params: { modelAction: 'store', imageData: imageData}, headers: { 'Content-Type': 'multipart/form-data', 'X-CSRF-TOKEN': AppHelpers.get_scrf_token() } };
			formData.append("file", image);
			
			post("/images/handle-image-request", formData, config).then(response => {
				console.log('response', response);
				const done = response.data;
				this.props.handleSingleImageUpload(imageData);
				this.setState({
					uploading : false
				});
				/*if(done){
					this.removeDroppedFile(image.preview);
				}*/
			})
			.catch(error => {
				// console.log('error.response.data', error);
			});
		});
	}

	onDrop = (value, side, images) => {

		var stateImages = this.state.images;
		stateImages[side] = stateImages[side].concat([...images]);
		console.log('stateImages', stateImages);
		
		this.setState({
			images : stateImages
		}, () => {
			this.uploadFiles(side)
		} );
	}

	onDropRejected = (images) => {
		if(images.length){
			toastr.error('Please upload valid image files. Supported extension JPEG and PNG', 'Invalid MIME type')
		}
	}

	render = () => {

		var artworkColumnClasses = classnames({
			"active-front": (this.props.activeSide == "front"),
			"active-back": (this.props.activeSide == "back"),
			"artwork-columns": true,
			"section-artwork-addons-inner": true
		});

		var frontInfo, backInfo, frontThumb, backThumb;

		if( this.props.front && Object.keys(this.props.front).length && !this.props.uploading) {
			var artworkUploadDropClassFront = "artwork-upload-drop artwork-upload-drop--has-thumb";
			frontThumb = <img src={this.props.front.fileUrl} className="image-info__thumb" />
			frontInfo = <a onClick={this.props.removeSideImage.bind(null, 'front')} className="image-info__btn form-button danger artwork-remove close-button"></a>
		}
		else {
			var artworkUploadDropClassFront = "artwork-upload-drop";
			artworkUploadDropClassFront += this.props.uploading ? "artwork-upload-drop--uploading" : '';
			frontInfo = <div className="dropzone-area-info">
				<p className="instructions">Click or drag and drop to upload your artwork here.</p>
				<p className="file-types">JPG, PNG, PSD, AI PDF</p>
			</div>
		}
		
		if( this.props.back && Object.keys(this.props.back).length && !this.props.uploading) {
			var artworkUploadDropClassBack = "artwork-upload-drop artwork-upload-drop--has-thumb";
			backThumb = <img src={this.props.back.fileUrl} className="image-info__thumb" />
			backInfo = <a onClick={this.props.removeSideImage.bind(null, 'back')} className="image-info__btn form-button danger artwork-remove close-button"></a>
		}
		else {
			var artworkUploadDropClassBack = "artwork-upload-drop";
			artworkUploadDropClassBack += this.props.uploading ? "artwork-upload-drop--uploading" : '';
			backInfo = <div className="dropzone-area-info">
				<p className="instructions">Click or drag and drop to upload your artwork here.</p>
				<p className="file-types">JPG, PNG, PSD, AI PDF</p>
			</div>
		}

		return (
			
			<div className="section-artwork-addons section-artwork">

				<div className={artworkColumnClasses}>
					<div className="artwork-column front">
						<h3>Front</h3>
						<div className="dropzone-outer preview-dropzone-outer">
							<div className="upload-drop upload-front-drop" id="upload-front-drop">
								{frontThumb}

								<Loader 
									show={this.state.uploading}
								/>
							
								<Dropzone
									onDropAccepted={this.onDrop.bind(null, this, 'front')}
									onDropRejected={this.onDropRejected.bind(this)}
									accept={this.state.supported_mime}
									multiple={false}
									className={artworkUploadDropClassFront}
								>
								{frontInfo}
								</Dropzone>
							</div>
						</div>
						
					</div>

					<div className="artwork-column back">
						<h3>Back</h3>
						<div className="dropzone-outer">
							<div className="upload-drop upload-back-drop" id="upload-back-drop">
								{backThumb}
								<Loader 
									show={this.state.uploading}
								/>
								<Dropzone
									onDropAccepted={this.onDrop.bind(null, this, 'back')}
									onDropRejected={this.onDropRejected.bind(this)}
									accept={this.state.supported_mime}
									multiple={false}
									className={artworkUploadDropClassBack}
								>
								{backInfo}
								</Dropzone>
							</div>
						</div>
						

					</div>
				</div>
			</div>
			
			)

	}

}
import QuoteStoreFunctions from './store-functions/QuoteStoreFunctions'

import ShirtStore from './ShirtStore'
import AddOnStore from './AddOnStore'
import SettingsStore from './SettingsStore'
import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
import EventEmitterObject  from 'events'

import update from 'immutability-helper'

import QuoteUtilities from '../utils/QuoteUtilities'

var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';
var CLASS_CHANGE_EVENT = 'change';

var _quote_store = {
	activeQuote: {},
	adjustedAllShirts: [],
	allAddOns: [],
	app_container_class: '',
	cartQuotes: {},
	cartTotalPrice: 0,
	customer_info: {
		customer_artwork: '',
		customer_email: '',
		customer_message: '',
		customer_name: '',
		customer_quote_title: '',
	},	
	filteredShirts: [],
	initialQuantity: 0,
	markupStrategies: [],
	printSetupFees: [],
	rawShirts: [],
	selectedShirtCache: false
}

var _getCartQuotes = function() {
	
	var cartQuotes = [];
	
	for(var i in _quote_store.cartQuotes) {
		cartQuotes.push(_quote_store.cartQuotes[i]);
	}

	return cartQuotes;
}


var _selectCartQuotePreview = function( unique_id ) {
	var quotes = _quote_store.cartQuotes;
	var quote = quotes[unique_id];
}



/*pricing*/
var getSideColours = function() {
	return _quote_store.activeQuote.sideColours;
}


/*move somewhere else*/
/*backcheck this*/
var getSelectedAddOns = function(just_ids = false) {

	var selectedAddOns = [];
	for( var i in _quote_store.activeQuote.selectedAddOns ) {
		var row = ( just_ids ) ? _quote_store.activeQuote.selectedAddOns[i].unique_id : _quote_store.activeQuote.selectedAddOns[i];
		selectedAddOns.push( row );
	}

	return selectedAddOns;

}

/**
 * Quote Store
 */

class QuoteStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	isActiveQuote() {
		return ( _quote_store.activeQuote.selectedShirt ) ? true : false;
	}

	getAdjustedShirts() {
		return _quote_store.adjustedAllShirts;
	}

	getShirtColours() {
		return (_quote_store.activeQuote.selectedShirt) ? _quote_store.activeQuote.selectedShirt.colours : false;
	}

	getSelectedShirt() {
		return _quote_store.activeQuote.selectedShirt;
	}

	getSelectedColour() {
		/*backcheck this*/
		_quote_store = QuoteStoreFunctions.handleDefaultColours( _quote_store );
		return _quote_store.activeQuote.selectedColour;
	}

	getSelectedSizes() {
		return _quote_store.activeQuote.selectedSizes;
	}

	getTotalQuantity() {
		var sizes = _quote_store.activeQuote.selectedSizes;
		var totals = QuoteUtilities.getSizesQuantityTotal( sizes );
		return totals;
	}

	getUploadedArtwork() {
		return _quote_store.activeQuote.uploadedArtwork;
	}

	getSelectedAddOns(just_ids) {
		return getSelectedAddOns(just_ids);
	}

	getSideColours() {
		return getSideColours();
	}

	getActiveQuote() {
		return _quote_store.activeQuote;
	}

	getCartQuotes() {
		return _getCartQuotes();
	}

	getMarkupStrategies() {
		return _quote_store.markupStrategies;
	}

	getCartTotals() {
		return {
			cartTotal: parseFloat(_quote_store.cartTotalPrice)
		}
	}

	getSubmitFormFieldValues() {
		return _quote_store.customer_info;
	}

	getAppContainerClass() {
		return _quote_store.app_container_class;
	}

	getActiveQuotePrintSetupFees() {
		return _quote_store.activeQuote.sideColours;
	}

	setAppContainerClass(data) {
		_quote_store.appContainerClass = data;
	}

	getActiveColourPreview() {
		return _quote_store.activeQuote.activeColourPreview;
	}

	getShirtColoursTotalTemp() {
		return _quote_store.activeQuote.shirtColoursTotalTemp;
	}

	getAllAddOns() {
		return _quote_store.allAddOns;
	}


};

const QuoteStore = new QuoteStoreClass();


AppDispatcher.register((payload) => {

	const action = payload;


	switch(action.actionType){

		case AppConstants.SET_QUOTE_TEMPLATE:

			_quote_store = QuoteStoreFunctions.setQuoteTemplate( _quote_store );
			
			break;
		case AppConstants.SET_DEFAULT_QUOTE_VALUES:

			/*AppDispatcher.waitFor([ShirtStore.dispatchToken]);
			AppDispatcher.waitFor([SettingsStore.dispatchToken]);
			AppDispatcher.waitFor([AddOnStore.dispatchToken]);*/
			_quote_store = QuoteStoreFunctions.setDefaultValues( _quote_store, action.data );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.SET_STORE_SHIRT_QUANTITY:

			_quote_store = QuoteStoreFunctions.setQuoteQuantity( _quote_store, action.data );
			break;
		case AppConstants.RECEIVE_RAW_SHIRTS:

			/*AppDispatcher.waitFor([ShirtStore.dispatchToken]);
			AppDispatcher.waitFor([SettingsStore.dispatchToken]);
			AppDispatcher.waitFor([AddOnStore.dispatchToken]);*/

			_quote_store = QuoteStoreFunctions.setRawShirts( _quote_store, ShirtStore.getAllShirts() );

			if(_quote_store.activeQuote.selectedShirtCache) {
				_quote_store = QuoteStoreFunctions.selectShirt( _quote_store.activeQuote.selectedShirtCache );
			}

			_quote_store = QuoteStoreFunctions.setStoreMarkupStrategies( _quote_store, SettingsStore.getMarkupStrategies() );
			_quote_store = QuoteStoreFunctions.setAdjustedShirts( _quote_store );
			
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.RECEIVE_RAW_ADDONS:

			/*AppDispatcher.waitFor([AddOnStore.dispatchToken]);
			AppDispatcher.waitFor([SettingsStore.dispatchToken]);*/

			var allAddOns = AddOnStore.getAllAddOns();
			setTimeout(function(){
				// _setQuoteStoreAddOns(allAddOns);
				_quote_store = QuoteStoreFunctions.setQuoteStoreAddOns( _quote_store , allAddOns );
			}, 500);
			break;
		case AppConstants.FILTER_SHIRTS:

			// AppDispatcher.waitFor([ShirtStore.dispatchToken]);

			_quote_store = QuoteStoreFunctions.setRawShirts( _quote_store,ShirtStore.getAllShirts() );

			_quote_store = QuoteStoreFunctions.setStorePrintSetupFees( _quote_store, SettingsStore.getPrintSetupFees() );
			_quote_store = QuoteStoreFunctions.setStoreMarkupStrategies( _quote_store, SettingsStore.getMarkupStrategies() );

			_quote_store = QuoteStoreFunctions.setAdjustedShirts(_quote_store);
			
			
			QuoteStore.emit(CHANGE_EVENT);

			break;
		case AppConstants.QUOTE_SELECT_SHIRT:

			/*AppDispatcher.waitFor([ShirtStore.dispatchToken]);
			AppDispatcher.waitFor([SettingsStore.dispatchToken]);
			AppDispatcher.waitFor([AddOnStore.dispatchToken]);*/
			_quote_store = QuoteStoreFunctions.selectShirt(_quote_store, action.data);

			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.INITIALIZE_QUOTE:

			_quote_store = QuoteStoreFunctions.initializeQuote(_quote_store);
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.QUOTE_SELECT_COLOUR:

			_quote_store = QuoteStoreFunctions.addSelectedColour( _quote_store, action.data );
			_quote_store = QuoteStoreFunctions.setQuoteColourTotals( _quote_store );
			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_COLOUR_SIZE:

			_quote_store = QuoteStoreFunctions.updateColourSize(_quote_store, action.data);
			_quote_store = QuoteStoreFunctions.setQuoteColourTotals( _quote_store );
			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.QUOTE_SELECT_ADDON:

			_quote_store = QuoteStoreFunctions.addSelectedAddOn(_quote_store, action.data);
			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPLOAD_ARTWORK:

			_quote_store.activeQuote.uploadedArtwork[action.data.side] = action.data;
			console.log('_quote_store.activeQuote', _quote_store.activeQuote)
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_SIDE_COLOUR:

			_quote_store = QuoteStoreFunctions.udpateSideColourQuantities( _quote_store, action.data);
			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.ADD_QUOTE_TO_CART:

			_quote_store = QuoteStoreFunctions.setActiveQuoteToCart(_quote_store);
			_quote_store = QuoteStoreFunctions.setCartTotalPrice( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.EDIT_SHIRT_QUOTE:

			_quote_store = QuoteStoreFunctions.addQuoteToActiveQuote( _quote_store, action.data);
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.SELECT_CART_QUOTE_PREVIEW:

			/*_selectCartQuotePreview(action.data);*/
			/*do we needs this?*/
			
			// QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.REMOVE_QUOTE_SHIRT:

			_quote_store = QuoteStoreFunctions.removeCartQuote(_quote_store, action.data);
			_quote_store = QuoteStoreFunctions.setActiveQuoteTotalsInfo( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_FORM_FORM_FIELD_VALUE:

			_quote_store.customer_info[action.data.field_key] = action.data.value;
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.SET_APP_CONTAINER_CLASS:

			_quote_store.app_container_class = action.data;
			QuoteStore.emit(CLASS_CHANGE_EVENT);
			break;
		case AppConstants.SUBMIT_QUOTE:

			_quote_store = QuoteStoreFunctions.submitResetQuote( _quote_store );
			
			QuoteStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = QuoteStore;
/**
 * Component Namespace: App/PanelMain/PanelSelectShirt
 */

import React from 'react'
import { render } from 'react-dom'
import { Router, Route, hashHistory, browserHistory } from 'react-router'

/*libraries*/
import classnames from 'classnames'

export default class ShirtFilterGrid extends React.Component {

	handleSelectCategory = (unique_id, e) => {
		e.preventDefault();
		this.props.filterShirts(unique_id);
	}

	getFilterCategory = ( category, index ) => {

		var categoryIndex = 'category-' + category.unique_id;

		var filterItemClasses = classnames({
			"shirt-filter-list-item": true,
			"current": this.props.selectedCategory == category.unique_id
		});

		var setActiveID = this.props.selectedCategory == category.unique_id ? '' : category.unique_id;
		// console.log(this.props.selectedCategory);
		
		return (
			<li key={categoryIndex} className={filterItemClasses}>
				<a
					href="#"
					onClick={this.handleSelectCategory.bind(null, setActiveID)}
					className="shirt-filter-button"
				>
					{category.title}
				</a>
			</li>
		)
	}

	render = () => {
		
		var categoryGrid;

		if(!this.props.categories)
			return false;

		categoryGrid = this.props.categories.map(this.getFilterCategory);

		var filterItemClasses = classnames({
			"shirt-filter-list-item": true,
			"current": !this.props.selectedCategory
		});

		return (
			
			<div className="shirt-filter-outer">
				<div className="filter-list-outer">
					<ul>
						{categoryGrid}
					</ul>
				</div>
			</div>
			
		)
	}
}
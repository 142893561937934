import React from 'react';
import ReactDom from 'react-dom';
import { Switch, Route, Link } from 'react-router-dom'

import classNames from 'classnames'

export default class AnimatedWrapper extends React.Component {

    render() {

        return (
        	<div className="main-panel-inner">
        		{this.props.children}
        	</div>
        );
    }
}
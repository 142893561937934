/**
 * Component Namespace: App/PanelMain/PanelSelectShirt
 */

import React from 'react'
import { render } from 'react-dom'
import { Router, Route, hashHistory, browserHistory, Link } from 'react-router'

/*stores*/
import ShirtStore from '../../../stores/ShirtStore'
import QuoteStore from '../../../stores/QuoteStore'
import CategoryStore from '../../../stores/CategoryStore'

/*components*/
import QuoteColourSizes from './QuoteColourSizes'

/*utils*/
import qu from '../../../utils/QuoteUtilities'

export default class SectionCartGrid extends React.Component {

	/*app functions*/
	constructor() {
		super();
		this.state = {
			shirts: [],
			addOns: [],
			notes: '',
			shirtColoursTotalPrice: 0
		}
	}

	componentDidMount = () => {

		this.setState( {
			shirts: [],
			addOns: [],
			notes: ''
		});

	}

	componentWillUnmount = () => {
	}

	getShirtInfoRow = (quote)  => {

		return (
			<div className="shirt-info-main">

				<span className="cart-item-colour-swatch" style={{backgroundColor: quote.selectedColour.hex}}></span>

				<header className="cart-colour-header cart-sub-list-item-header">


					<div className="cart-colour-title-outer">
						<div className="cart-colour-title cart-sub-list-item-title">
							<h6>{quote.selectedColour.title}</h6>
						</div>
						<span className="cart-colour-price cart-sub-list-item-total">{qu.getPriceFromString(quote.shirtPricing.totalShirtPrice)}</span>
					</div>

					<div className="cart-colour-info">
						<ul className="preview-cart-info-list">
							<li><span className="preview-cart-item-value">{qu.getPriceFromString(quote.shirtPricing.totalPricePerShirt)}</span>/shirt</li>
						</ul>
					</div>
					
				</header>
				<QuoteColourSizes
					colourSizes={quote.selectedSizes}
				/>
			</div>
		)

	}

	getAddOnRow = (addOn)  => {
		return (
			<div className="cart-addon-item-inner">	
				<div className="addon-icon-outer">
					<span className="lnr lnr-cart"></span>
				</div>
				<h6>{addOn.title}</h6>
			</div>
		)
	}

	getAddOnsList = (selectedAddOns)  => {
		
		var addOns = [];

		for(var i in selectedAddOns) {
			var addOnRow = this.getAddOnRow( selectedAddOns[i] );
			addOns.push( <li className="cart-addon-item" key={selectedAddOns[i].unique_id }>{addOnRow}</li> );
		}

		return (
			 <div className="cart-list-outer cart-list-outer-addons">
			 	<h4 className="section-label">Add Ons</h4>
			 	<ul className="cart-item-list cart-shirt-addons-list">{addOns}</ul>
			 </div>
		) 

	}

	getCartPriceTotals = (cartTotals)  => {

		return (
			<div className="cart-price-totals-outer">
				<div className="cart-price-totals">
					<h3>Your Total:</h3>
					<span>{qu.getPriceFromString(cartTotals.cartTotal)}</span>
				</div>
			</div>
		);
		
	}

	getCartQuote = (quote, index)  => {

		var shirtInfoRow = this.getShirtInfoRow(quote);
		var addOns = this.getAddOnsList(quote.selectedAddOns);

		return (
			<li key={quote.unique_id} className="cart-item" onClick={this.props.setCartActiveQuote(quote.unique_id)}>

				<header className="cart-shirt-header">
					<div className="cart-shirt-header-title">
						<h4>{quote.selectedShirt.title}</h4>
						<p>{quote.selectedShirt.brand} | {quote.shirtTotalQuantity}</p>
					</div>
					<ul className="cart-shirt-actions">
						<li><a href="#" className="edit-shirt-quote" onClick={this.props.handleRemoveShirtQuote.bind(null, quote.unique_id)}>Remove</a></li>
						<li>
							<a href="#" className="edit-shirt-quote" onClick={this.props.hanldeEditShirtQuote.bind(null, quote.unique_id, quote.shirtTotalQuantity, index)}>Edit</a>
						</li>
					</ul>
				</header>

				<div className="cart-shirt-content">
				
					<div className="cart-shirt-thumbnail">
						<div className="cart-shirt-thumbnail-inner">
							<img src={quote.selectedColour.image_front.sizes.medium} alt={quote.selectedShirt.title} />
						</div>
					</div>
				
					<div className="cart-shirt-info">
						{shirtInfoRow}
						{addOns}
					</div>

				</div>
			</li>
		);	
	}

	render = () => {

		var cart, cartTotalsInfo;

		if( this.props.cartQuotes.length < 1 )
			return false;
		if( Object.keys(this.props.cartQuotes[0]).length < 1 )
			return false;
		if( (this.props.cartQuotes[0].shirtTotalPrice < 1 ) )
			return false;
		if( !this.props.cartTotals )
			return false;

		cart = this.props.cartQuotes.map(this.getCartQuote);
		cartTotalsInfo = this.getCartPriceTotals(this.props.cartTotals);

		return (
			
			<div className="panel-section panel-section-cart">
				<ul className="quote-cart">
					{cart}
					{cartTotalsInfo}
				</ul>
			</div>
			
		)
	}
}
import AppServerActionCreators from '../actions/AppServerActionCreators';
import request from 'superagent/lib/client';

import AppHelpers from './AppHelpers';

var debug = true;

var showErrors = function(err, res) {
	if( !debug ) {
		console.log(err);
		console.log(res.text);
	}

	if( res.status == 500 || 404 == res.status ) {
		var content = `<div class="error-body" id="error-body">${res.text}</div>`;
		$('.body-container').prepend(content);
	}

}

module.exports = {

	getAllAppData: function() {
		this.handleShirtData({ modelAction: 'get-all' });
		this.handleColourData({ modelAction: 'get-all' });
		this.handleCategoryData({ modelAction: 'get-all' });
		this.handleAddOnData({ modelAction: 'get-all' });
		this.handleMarkupStrategyData({ modelAction: 'get-all' });
		this.handleSettingsData({ modelAction: 'get-print-setup-fees' });
	},

	/**
	 * Insert new shirt into database
	 */
	handleShirtData: function(data) {
		request
			.post('/shirts/handle-shirt-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
					AppServerActionCreators.handleShirtServerResponse(res.body);
			}
		});

	},
	
	handleCategoryData: function(data) {
		request
			.post('/shirts/handle-category-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleCategoryServerResponse(res.body);
			}
		});

	},
	/**
	 * Insert new colour into database
	 */
	handleColourData: function(data) {

		request
			.post('/colours/handle-colour-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleColourServerResponse(res.body);
			}
		});


	},

	/**
	 * Insert new image into database
	 */
	handleImageData: function(data) {

		request
			.post('/images/handle-image-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					// showErrors(err, res);
					console.log(res);
				} 
				else {
					console.log('res.body', res.body);
					AppServerActionCreators.handleImageServerResponse(res.body);
			}
		});

	},

	handleMarkupStrategyData: function(data) {

		var elem = document.getElementById("error-body");
		if( elem ) {
			elem.parentNode.removeChild(elem);
		}

		request
			.post('/markup-strategies/handle-markup-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleSettingsServerResponse(res.body);
			}
		});

	},

	handleAddOnData: function(data) {

		request
			.post('/addons/handle-addon-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleAddOnServerResponse(res.body);
			}
		});

	},

	handleSettingsData: function(data) {

		request
			.post('/settings/handle-settings-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleSettingsServerResponse(res.body);
			}
		});

	},

	handleQuoteData: function(data) {
		request
			.post('/quotes/handle-quote-request')
			.send(data)
			.set('X-CSRF-TOKEN', AppHelpers.get_scrf_token())
			.end(function(err, res){
				if (err || !res.ok) {
					showErrors(err, res);
				} 
				else {
				AppServerActionCreators.handleAdminQuoteServerResponse(res.body);
			}
		});
	}

};

/**
 * Component Namespace: App
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/*components*/
import Header from './header/Header'
import PanelMain from './panel-main/PanelMain'
import PreviewPane from './preview-pane/PreviewPane'
import SideBar from './sidebar/SideBar'
import AnimatedWrapper from './AnimatedWrapper'

/*route components*/
import PanelSelectShirt from './panel-main/PanelSelectShirt/'
import PanelCustomize from './panel-main/PanelCustomize/'
import PanelSubmit from './panel-main/PanelSubmit/'
import PanelQuoteConfirm from './panel-main/PanelQuoteConfirm/'

/*utitlities*/
/*import AppWebAPIUtilities from '../utils/AppWebAPIUtilities'*/
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import queryString from 'query-string'


/*stores*/
import QuoteStore from '../stores/QuoteStore'
import ShirtStore from '../stores/ShirtStore'
import CategoryStore from '../stores/CategoryStore'
import SettingsStore from '../stores/SettingsStore'

/*actions*/
import QuoteActionCreators from '../actions/QuoteActionCreators'

/*libraries*/
import classNames from 'classnames'

export default class App extends React.Component {

	constructor() {
		super();
		this.state = {
			activeQuote: null,
			isActiveQuote: false,
			currentRoutePath: '',
			appContainerClass: '',
			shirts: null,
			categories: [],
		}
	}

	componentDidMount = () => {

		QuoteStore.addChangeListener(this._onQuoteStoreChange);

		this.handleSetQuoteTemplate();
		this.handleQuoteDefaultValues();

	}

	handleSetQuoteTemplate = () => {
		QuoteActionCreators.setQuoteTemplate();
	}

	componentWillUnmount = () => {
		
		CategoryStore.removeChangeListener(this._onCategoryWebChange);
		QuoteStore.removeChangeListener(this._onQuoteStoreChange);

	}

	_onCategoryWebChange = () =>  {
		this.setState({
			categories: CategoryStore.getAllCategories()
		});
	}

	_onQuoteStoreChange = () =>  {
		
		this.setState({
			isActiveQuote: QuoteStore.isActiveQuote(),
			appContainerClass: QuoteStore.getAppContainerClass()
		});

	}

	handleQuoteDefaultValues = () =>  {

		const parsed = queryString.parse(location.search);

		if( parsed.selectedShirt !== "0" ) {
			/*backcheck this*/
			// var selectedShirtArray = parsed.selectedShirt.split("|");
			// var shirt_unique_id = selectedShirtArray[0];
		}
		else {
			var shirt_unique_id = false;
		}	

		var defaultQuoteValues = {
			selectedShirt: shirt_unique_id,
			quantity: parseInt(parsed.quantity),
		}

		QuoteActionCreators.setQuoteDefaultValues(defaultQuoteValues);

	}

	componentWillUnmount = () => {
		QuoteStore.removeChangeListener(this._onChange);
	}

	_onChange = () => {
		this.setState({
			isActiveQuote: QuoteStore.isActiveQuote(),
			appContainerClass: QuoteStore.getAppContainerClass()
		});
	}

	doSomething = (value) => {
		console.log('doSomething called by child with value:' + value);
	}

	render = () => {

		var containerClasses = classNames({
			'app-container': true,
			'app-wrapper': true,
			'sidebar-closed': !this.state.isActiveQuote,
			'shirt-selected': this.state.isActiveQuote
		});

	 	var appClasses = classNames({
	 		'app-wrapper': true,
	 	});

		containerClasses += ' ' + this.state.currentRoutePath;

        var mainOuterClasses = classNames({
        	"main-panel-outer": true,
        	"app-column": true,
        	"app-column-center": true
        })

		return (
			
	 		<div className={containerClasses}>
		 		<div className="app-wrapper-outer" >
		 			
		 			<Header {...this.props}/>
		            
		            <Route render={({ location }) => (
		              <div className="app-container-outer">
			 			
			 			<SideBar {...this.props}/>
			 			<div className={mainOuterClasses}>
			              <TransitionGroup>
					              <AnimatedWrapper
						              location={location}
						              locationKey={location.pathname.split('/')[1]}
						              handleWrapperOuterHeight={this.setOuterWrapperHeight}
					              >
								<Switch>

		                            <Route path="/" exact render={()=>(
		                                <PanelSelectShirt  />
		                            )} />

		                            <Route path="/customize"  render={()=>(
		                                <PanelCustomize  />
		                            )} />
		                            <Route path="/submit"  render={()=>(
		                                <PanelSubmit 
			                                activeQuote={this.state.activeQuote}
			                                allAddOns={this.state.allAddOns}
		                                 />
		                            )} />
		                            <Route path="/confirm"  render={()=>(
		                                <PanelQuoteConfirm   />
		                            )} />
                      			</Switch>
	                     		</AnimatedWrapper> 
	                  		</TransitionGroup>

              		</div>
          		<PreviewPane />
          		</div>
					)}/>{/*<Route*/}
				
			</div>{/*wrapper-outer*/}
 		</div>

		)
	}
}
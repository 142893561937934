var AppConstants = {

	/**
	 * App
	 */
	INITIALIZE_QUOTE: "INITIALIZE_QUOTE", 
	SET_STORE_SHIRT_QUANTITY: "SET_STORE_SHIRT_QUANTITY", 
	QUOTE_SELECT_SHIRT: "QUOTE_SELECT_SHIRT", 
	QUOTE_SELECT_COLOUR: "QUOTE_SELECT_COLOUR", 
	QUOTE_SELECT_ADDON: "QUOTE_SELECT_ADDON",
	UPDATE_COLOUR_SIZE: "UPDATE_COLOUR_SIZE",
	UPDATE_SIDE_COLOUR: "UPDATE_SIDE_COLOUR",
	REMOVE_COLOUR: "REMOVE_COLOUR",
	UPLOAD_ARTWORK: "UPLOAD_ARTWORK",
	ADD_QUOTE_TO_CART: "ADD_QUOTE_TO_CART",
	SELECT_CART_QUOTE_PREVIEW: "SELECT_CART_QUOTE_PREVIEW",
	ADD_MARKUP_STRATEGIES: "ADD_MARKUP_STRATEGIES",
	UPDATE_PRINT_FEES: "UPDATE_PRINT_FEES",
	EDIT_SHIRT_QUOTE: "EDIT_SHIRT_QUOTE",
	REMOVE_QUOTE_SHIRT: "REMOVE_QUOTE_SHIRT",
	UPDATE_FORM_FORM_FIELD_VALUE: "UPDATE_FORM_FORM_FIELD_VALUE",
	SUBMIT_QUOTE: "SUBMIT_QUOTE",
	ADD_PRINT_SETUP_FEES: "ADD_PRINT_SETUP_FEES",
	RECEIVE_RAW_PRINT_SETUP_FEES: "RECEIVE_RAW_PRINT_SETUP_FEES",
	SET_ROUTE_PATH: "SET_ROUTE_PATH",
	SET_APP_CONTAINER_CLASS: "SET_APP_CONTAINER_CLASS",
	SET_DEFAULT_QUOTE_VALUES: "SET_DEFAULT_QUOTE_VALUES",
	SET_QUOTE_TEMPLATE: "SET_QUOTE_TEMPLATE",
	/**
	 * Admin
	 */
	/*shirts*/
	CREATE_SHIRT: "CREATE_SHIRT",
	UPDATE_SHIRT: "UPDATE_SHIRT",
	RECEIVE_UPDATED_SHIRT: "RECEIVE_UPDATED_SHIRT",
	DELETE_SHIRT: "DELETE_SHIRT",
	RECEIVE_RAW_SHIRTS: "RECEIVE_RAW_SHIRTS",
	RECEIVE_ADDED_SHIRT: "RECEIVE_ADDED_SHIRT",
	SET_IMAGE_FILTER: "SET_IMAGE_FILTER",
	/*Shirts*/
	FILTER_SHIRTS: "FILTER_SHIRTS",
	SEARCH_SHIRTS: "SEARCH_SHIRTS",
	/*Categories*/
	CREATE_CATEGORY: "CREATE_CATEGORY",
	UPDATE_CATEGORY: "UPDATE_CATEGORY",
	DELETE_CATEGORY: "DELETE_CATEGORY",
	RECEIVE_RAW_CATEGORIES: "RECEIVE_RAW_CATEGORIES",
	RECEIVE_ADDED_CATEGORY: "RECEIVE_ADDED_CATEGORY",
	/*colours*/
	CREATE_COLOUR: "CREATE_COLOUR",
	UPDATE_COLOUR: "UPDATE_COLOUR",
	DELETE_COLOUR: "DELETE_COLOUR",
	RECEIVE_RAW_COLOURS: "RECEIVE_RAW_COLOURS",
	RECEIVE_ADDED_COLOUR: "RECEIVE_ADDED_COLOUR",
	/*images*/
	CREATE_IMAGE: "CREATE_IMAGE",
	RECEIVE_RAW_IMAGES: "RECEIVE_RAW_IMAGES",
	RECEIVE_ADDED_IMAGE: "RECEIVE_ADDED_IMAGE",
	DELETE_IMAGE: "DELETE_IMAGE",
	UPDATE_IMAGE: "UPDATE_IMAGE",
	SET_FILTER_TEXT: "SET_FILTER_TEXT",
	/*markup strategies*/
	RECEIVE_RAW_MARKUP_STRATEGIES: "RECEIVE_RAW_MARKUP_STRATEGIES",
	UPDATE_MARKUP_STRATEGY: "UPDATE_MARKUP_STRATEGY",
	/*addons*/
	CREATE_ADDON: "CREATE_ADDON",
	UPDATE_ADDON: "UPDATE_ADDON",
	DELETE_ADDON: "DELETE_ADDON",
	RECEIVE_RAW_ADDONS: "RECEIVE_RAW_ADDONS",
	RECEIVE_ADDED_ADDON: "RECEIVE_ADDED_ADDON",
	/*addons*/
	GET_RAW_QUOTES: "GET_RAW_QUOTES",
	RECEIVE_RAW_ADMIN_QUOTES: "RECEIVE_RAW_ADMIN_QUOTES",
};

module.exports = AppConstants;
/**
 * Component Namespace: App/PreviewPane/PreviewCart
 */

import React from 'react'
import { render } from 'react-dom'

export default class QuoteColourSizes extends React.Component {

	render = () => {
		
		return (
			<ul className="cart-colour-sizes cart-sub-list-item-details">
				<li>
					<span className="size-label">XS</span>
					<span className="size-value">{this.props.colourSizes.extraSmall}</span>
				</li>
				<li>
					<span className="size-label">S</span>
					<span className="size-value">{this.props.colourSizes.small}</span>
				</li>
				<li>
					<span className="size-label">M</span>
					<span className="size-value">{this.props.colourSizes.medium}</span>
				</li>
				<li>
					<span className="size-label">L</span>
					<span className="size-value">{this.props.colourSizes.large}</span>
				</li>
				<li>
					<span className="size-label">XL</span>
					<span className="size-value">{this.props.colourSizes.extraLarge}</span>
				</li>
				<li>
					<span className="size-label">2XL</span>
					<span className="size-value">{this.props.colourSizes.twoExtraLarge}</span>
				</li>
				<li>
					<span className="size-label">3XL</span>
					<span className="size-value">{this.props.colourSizes.threeExtraLarge}</span>
				</li>
			</ul>
		)
	}
}
import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
// import objectAssign from 'react/lib/Object.assign'
import EventEmitterObject  from 'events'
// var EventEmitter = require('events').EventEmitter;
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _categoryStore = {
	categories: {},
	filteredCategories: []
}

var _addCategories = function(data) {

	for( var i in data ) {
		var category = data[i];
		if( !_categoryStore.categories[category.unique_id] ) {
			_categoryStore.categories[category.unique_id] = category;
		}
	}

}

var getAllCategories = function() {

	var categories = [];
	
	for(var i in _categoryStore.categories) {
		categories.push(_categoryStore.categories[i])
	}

	categories.sort(function(a, b) {
		if (b.created_at < a.created_at) {
			return -1;
		} 
		else if (b.created_at > a.created_at) {
			return 1;
		}
		return 0;
	});
	
	return categories;

}

// var CategoryStore = objectAssign({}, EventEmitter.prototype, {
class CategoryStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getAllCategories(){
		return getAllCategories();
	}

	getCategory(unique_id){
		return _categoryStore.categories[unique_id];
	}

};

const CategoryStore = new CategoryStoreClass();

AppDispatcher.register((payload) => {

	const action = payload;

	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_CATEGORIES:
			_addCategories(action.data.data);
			CategoryStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.CREATE_CATEGORY:
			_categoryStore.categories[action.data.unique_id] = action.data;
			CategoryStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_CATEGORY:
			_categoryStore.categories[action.data.unique_id] = action.data;
			CategoryStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.DELETE_CATEGORY:
			delete _categoryStore.categories[action.data.unique_id];
			CategoryStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = CategoryStore;

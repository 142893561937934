import React from 'react'
import { render } from 'react-dom'

import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
import QuoteUtilities from '../utils/QuoteUtilities'
import AppWebAPIUtilities from '../utils/AppWebAPIUtilities'
import moment from 'moment'

module.exports = {

	setAppContainerClass: function(containerClass) {
		AppDispatcher.dispatch({
			actionType: AppConstants.SET_APP_CONTAINER_CLASS,
			data: containerClass
		});
	},

	setQuoteQauntity: function(quantity) {
		AppDispatcher.dispatch({
			actionType: AppConstants.SET_STORE_SHIRT_QUANTITY,
			data: quantity
		});
	},

	selectShirt: function( unique_id ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.QUOTE_SELECT_SHIRT,
			data: unique_id
		});
	},

	setQuoteTemplate: function() {
		AppDispatcher.dispatch({
			actionType: AppConstants.SET_QUOTE_TEMPLATE,
			data: null
		});
	},

	setQuoteDefaultValues: function( data ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.SET_DEFAULT_QUOTE_VALUES,
			data: data
		});
	},

	initializeQuote: function(data) {
		AppDispatcher.dispatch({
			actionType: AppConstants.INITIALIZE_QUOTE,
			data: data
		});
	},

	selectColour: function( colour, markupStrategies, selectedShirt, shirtColoursTotalTemp, selectedSizes ) {
		var data = QuoteUtilities.formatSelectedColour( colour, markupStrategies, selectedShirt, shirtColoursTotalTemp, selectedSizes );
		AppDispatcher.dispatch({
			actionType: AppConstants.QUOTE_SELECT_COLOUR,
			data: data
		});
	},

	updateColour: function( colour, markupStrategies, selectedShirt ) {
		var data = QuoteUtilities.formatSelectedColour( colour, markupStrategies, selectedShirt );
		AppDispatcher.dispatch({
			actionType: AppConstants.UPDATE_COLOUR,
			data: data
		});
	},

	updateSelectedColourSize: function( colour ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.UPDATE_COLOUR_SIZE,
			data: colour
		});
	},

	removeColour: function( unique_id ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.REMOVE_COLOUR,
			data: unique_id
		});
	},

	uploadArtwork: function( upload ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.UPLOAD_ARTWORK,
			data: upload
		});
	},

	addQuoteToCart: function() {
		AppDispatcher.dispatch({
			actionType: AppConstants.ADD_QUOTE_TO_CART
		});
	},

	removeShirtQuote: function(unique_id) {
		AppDispatcher.dispatch({
			actionType: AppConstants.REMOVE_QUOTE_SHIRT,
			data: unique_id
		});
	},

	addQuoteToActiveQuote: function( unique_id ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.EDIT_SHIRT_QUOTE,
			data: unique_id
		});
	},


	selectCartQuotePreview: function( unique_id ) {
		AppDispatcher.dispatch({
			actionType: AppConstants.SELECT_CART_QUOTE_PREVIEW,
			data: unique_id
		});
	},

	addMarkupStrategies: function(data) {
		AppDispatcher.dispatch({
			actionType: AppConstants.ADD_MARKUP_STRATEGIES,
			data: data
		});
	},

	addPrintSetupFees: function(data) {
		AppDispatcher.dispatch({
			actionType: AppConstants.ADD_PRINT_SETUP_FEES,
			data: data
		});
	},

	updateSubmitFormFieldValue(data) {
		AppDispatcher.dispatch({
			actionType: AppConstants.UPDATE_FORM_FORM_FIELD_VALUE,
			data: data
		});
	},

	submitQuote: function(data) {

		var formatedQuote = QuoteUtilities.formatCreatedQuote(data);
		formatedQuote.modelAction = 'store';

		AppDispatcher.dispatch({
			actionType: AppConstants.SUBMIT_QUOTE,
			data: formatedQuote
		});

		AppWebAPIUtilities.handleQuoteData(formatedQuote);
		
	}

};

/**
 * Component Namespace: App/PanelMain/PanelArtwork
 */

 import React from 'react'
 import { render } from 'react-dom'
 import ReactDOMServer from 'react-dom/server'
 import { Router, Route, hashHistory } from 'react-router'

import Dropzone from 'react-dropzone';
import request from 'superagent/lib/client';
import {post} from 'axios';

 /*stores*/
 import ImageStore from '../../../stores/ImageStore'
 import QuoteStore from '../../../stores/QuoteStore'

 /*libraries*/
 import Modal from 'react-modal'
 import classnames from 'classnames'
 import update from 'immutability-helper'

 /*actions*/
 import QuoteActionCreators from '../../../actions/QuoteActionCreators'

 /*utils*/
 import AppHelpers from '../../../utils/AppHelpers'
 import AppUtilities from '../../../utils/AppUtilities'


 export default class FormRowArtwork extends React.Component {

	constructor() {
		super();
		this.state = {
			images : [],
            progress : 0,
            uploading : true,
            supported_mime : [
                'image/jpeg',
                'image/png',
                'image/vnd.adobe.photoshop',
                'application/postscript'
            ]
		}
	}

	handleUploadSuccess = (data) => {

		console.log(data);

		var object = JSON.parse(data.xhr.response);
		this.props.handleUploadSuccess(object);

	}

	removeDroppedFile(name, e){
		e.preventDefault();
		this.setState({
			images : this.state.images.filter((image) => {
				return image.name !== name
			})
		})
	}

	uploadFiles = () => {

		let images = this.state.images,
		total_files = this.state.images.length,
		uploaded = 0;

		console.log('images', images);

		this.setState({
			uploading : true
		});

		images.map((image) => {

			let formData = new FormData();
			
			var d = new Date();
			var upload_timestamp = d.getTime();
			var imageData = AppUtilities.formatCreatedImage(image.name, upload_timestamp, null, 2);

			var config = { params: { modelAction: 'store', imageData: imageData}, headers: { 'Content-Type': 'multipart/form-data', 'X-CSRF-TOKEN': AppHelpers.get_scrf_token() } };
			formData.append("file", image);
			
			post("/images/handle-image-request", formData, config).then(response => {
				console.log('response', response);
				const done = response.data;
				
				if(done){
					this.removeDroppedFile(image.preview);
				}
			})
			.catch(error => {
				console.log('error.response.data', error);
			});
		});
	}

	onDrop(images){
		console.log('images', images);
		this.setState({
			images : this.state.images.concat([...images])
		}, () => {
			this.uploadFiles()
		} );
		
	}

	onDropRejected(images){
		if(images.length){
			toastr.error('Please upload valid image files. Supported extension JPEG and PNG', 'Invalid MIME type')
		}
	}

	render = ()=> {

		return (
			
			<div className="section-artwork-addons section-artwork">
				<div className="artwork-columns section-artwork-addons-inner">
					<div className="artwork-column">
						<div className="dropzone-outer">
							<Dropzone
								onDropAccepted={this.onDrop.bind(this)}
								onDropRejected={this.onDropRejected.bind(this)}
								accept={this.state.supported_mime}
								multiple={false}
								className="section-artwork__upload-button button button--purple"
							>
								Click to upload a file
							</Dropzone>
						</div>
                        <div className="upload-info">
                        {
                            this.state.images.map((file) =>
                                <div key={file.name} className="dropzone-preview__image section-artwork__file">
                                    <p className="section-artwork__file-name"><strong>{file.name}</strong> <a href="#" onClick={this.removeDroppedFile.bind(this, file.name)}>Remove</a></p>
                                </div>
                            )
                        }
                        </div>
					</div>
				</div>
			</div>
			
			)

	}

}
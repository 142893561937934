/**
 * Component Namespace: App/Sidebar
 */

import React from 'react'
// import { render } from 'react-dom'
import { Router, Route, hashHistory } from 'react-router'

import SidebarNavigation from './SidebarNavigation'

import QuoteStore from '../../stores/QuoteStore'

import classnames from 'classnames'

// export default React.createClass({
export default class SideBar extends React.Component {

	render(){
		
		var pathClass = ( '/' == this.props.location.pathname ) ? 'shirts' : this.props.location.pathname.substring(1);
		
		var sidebarClasses = classnames({
			"sidebar": true,
			"app-column": true,
			"app-column-sidebar": true,
			[ `sidebar--${pathClass}`]: true
		});

		return (
			<div className={sidebarClasses}>

				<div className="sidebar__inner panel-inner">
					
					<div className="sidebar__progress-border sidebar-progress-border"><span></span></div>
					<div className="sidebar__active-nav-bg sidebar-active-nav-background"></div>

					<SidebarNavigation {...this.props}/>
				</div>
			</div>
		)
	}
};
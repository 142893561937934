/**
* Component Namespace: App/PanelMain/PanelArtwork
*/

import React from 'react'
import { render } from 'react-dom'

/*libraries*/
import classnames from 'classnames'

import QuoteUtilities from '../../../utils/QuoteUtilities'


export default class SectionAddOns extends React.Component {

	handleSelectAddOn = (addOn, e) => {
		e.preventDefault();
		this.props.selectAddOn(addOn);
	}

	getAddOn = (addOn, index) => {

		var addOnCardClasses = classnames({
			"card": true,
			"addon-list-item": true,
			"app-addons__item": true,
			"app-addons__item--active": this.props.selectedAddOns.indexOf(addOn.unique_id) != -1
		});

		return (
			<li key={addOn.unique_id} className={addOnCardClasses}>
				<div className="addon-list-item app-addons__item-inner">
					<a onClick={this.handleSelectAddOn.bind(null, addOn)} href="#" className="app-addons__link">
						<div className="addon-icon-outer app-addons__icon-outer">
							<span className="addon-icon app-addons__icon"></span>
							<span className="addon-icon-bg app-addons__icon-bg"></span>
						</div>
						<h5 className="app-addons__title">{addOn.title}</h5>
						<p className="app-addons__price">{QuoteUtilities.getPriceFromString(addOn.adjustedPricing.addOnTotalPricePerShirt)}<span>/shirt</span></p>
					</a>
				</div>
			</li>
		)
	}

	render = () => {

		var addOnGrid;

		if( !this.props.allAddOns )
			return;
		
		addOnGrid = this.props.allAddOns.map(this.getAddOn);

		return (
			
			<div className="section-artwork-addons section-addons app-addons">
				<h3>Add Ons</h3>

				<div className="section-artwork-addons-inner app-addons__inner">
					<ul className="addons-grid app-addons__list">
						{addOnGrid}
					</ul>
				</div>
			</div>
			
		)

	}

}
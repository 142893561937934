import React from 'react'
import { render } from 'react-dom'

/*libraries*/
import update from 'immutability-helper'

/*components*/
import ColourQuantityForm from './ColourQuantityForm'

export default class ColourQuantityForms extends React.Component {

	constructor() {
		super();
		this.state = { 
			selectedSizes: null,
			selectedColour: null,
		};
	}

	handleSingleSizeUpdate = (size, value) => {
		this.props.handleSingleSizeUpdate(size, value);
	}

	getQuantityFormComponent = ( colour ) => {

		return (
			<div key={colour.unique_id}>
				<ColourQuantityForm 
					sizes={this.props.selectedSizes}
					hex={colour.hex}
					title={colour.title}
					unique_id={colour.unique_id}
					handleSingleSizeUpdate={this.handleSingleSizeUpdate}
				/>
			</div>	
		)
		
	}

	render = () => {
		
		var colourQuantiyForms;

		if(this.props.selectedSizes && this.props.selectedColour ) {

			colourQuantiyForms = this.getQuantityFormComponent(this.props.selectedColour);
			
		}

		return (
			<div className="colour-qty-forms-wrapper">
					{colourQuantiyForms}
			</div>
		);
	}

}
import AppDispatcher from '../dispatcher/AppDispatcher';
import AppConstants from '../constants/AppConstants';

module.exports = {

	handleShirtServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawShirts':
				var contstant = AppConstants.RECEIVE_RAW_SHIRTS;
				break;
			case 'receiveAddedShirt':
				var contstant = AppConstants.RECEIVE_ADDED_SHIRT;
				break;
			case 'receiveUpdatedShirt':
				var contstant = AppConstants.RECEIVE_UPDATED_SHIRT;
				break;
			default:
				break;
		}

		
		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

	handleCategoryServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawProductCategories':
				var contstant = AppConstants.RECEIVE_RAW_CATEGORIES;
				break;
			case 'receiveAddedCategory':
				var contstant = AppConstants.RECEIVE_ADDED_CATEGORY;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

	handleColourServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawColours':
				var contstant = AppConstants.RECEIVE_RAW_COLOURS;
				break;
			case 'receiveAddedColour':
				var contstant = AppConstants.RECEIVE_ADDED_COLOUR;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},
	
	handleImageServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawImages':
				var contstant = AppConstants.RECEIVE_RAW_IMAGES;
				break;
			case 'receiveAddedImage':
				var contstant = AppConstants.RECEIVE_ADDED_IMAGE;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

	handleSettingsServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawMarkup':
				var contstant = AppConstants.RECEIVE_RAW_MARKUP_STRATEGIES;
				break;
			case 'receiveRawPrintSetupFees':
				var contstant = AppConstants.RECEIVE_RAW_PRINT_SETUP_FEES;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

	handleAddOnServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawAddOns':
				var contstant = AppConstants.RECEIVE_RAW_ADDONS;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

	handleAdminQuoteServerResponse: function(response) {

		switch(response.success) {
			case 'receiveRawAdminQuotes':
				var contstant = AppConstants.RECEIVE_RAW_ADMIN_QUOTES;
				break;
			default:
				break;
		}

		AppDispatcher.dispatch({
			actionType: contstant,
			data: response
		})

	},

};

import moment from 'moment'

module.exports = {

	formatCreatedShirt: function(createdShirt) {

		var unique_id = ( createdShirt.unique_id ) ? createdShirt.unique_id : Math.floor(Math.random() * 1000000000) + 10000;
		var created_at = ( createdShirt.unique_id ) ? createdShirt.created_at : moment().utc().format('YYYY-MM-DD HH:mm:ss');
		var colours = [];

		for(var i in createdShirt.colours) {
			var colour = this.formatCreatedColour(createdShirt.colours[i]);
			colours.push(colour);
		}

		var shirt =  {
			base_price: createdShirt.base_price,
			base_price_white: createdShirt.base_price_white,
			brand: createdShirt.brand,
			colours: colours,
			description: createdShirt.description,
			created_at: created_at,
			markup_strategy: createdShirt.markup_strategy,
			product_category: createdShirt.product_category,
			primaryColour: createdShirt.primaryColour,
			style_code: createdShirt.style_code,
			title: createdShirt.title,
			unique_id: unique_id,
		}

		return shirt;
		
	},

	formatCreatedColour: function(createdColour) {

		var unique_id = ( createdColour.unique_id ) ? createdColour.unique_id : Math.floor(Math.random() * 1000000000) + 10000;
		var created_at = ( createdColour.unique_id ) ? createdColour.created_at : moment().utc().format('yyyy-mm-dd hh:mm:ss');

		var white_base_price = ( typeof createdColour.white_base_price != 'undefined') ? createdColour.white_base_price : 0;

		var colour =  {
			created_at: created_at,
			hex: createdColour.hex,
			title: createdColour.title,
			unique_id: unique_id,
			white_base_price: white_base_price,
			image_back: (typeof createdColour.image_back !== 'undefined') ? createdColour.image_back: null,
			image_front: (typeof createdColour.image_front !== 'undefined') ? createdColour.image_front: null,
			requires_base: createdColour.requires_base,
			primary: createdColour.primary,
		}

		return colour;

	},

	
	formatCreatedCategory: function(createdCategory) {

		var unique_id = ( createdCategory.unique_id ) ? createdCategory.unique_id : Math.floor(Math.random() * 1000000000) + 10000;
		var created_at = ( createdCategory.unique_id ) ? createdCategory.created_at : moment().utc().format('yyyy-mm-dd hh:mm:ss');
		var shirts = ( createdCategory.unique_id ) ? createdCategory.shirts : [];

		var category =  {
			created_at: created_at,
			featured_image: createdCategory.featured_image,
			image_unique_id: createdCategory.image_unique_id,
			shirts: shirts,
			title: createdCategory.title,
			unique_id: unique_id,
		}

		return category;

	},

	string_to_slug: function(str) {
	    str = str.replace(/^\s+|\s+$/g, ''); // trim
	    str = str.toLowerCase();

	    // remove accents, swap ñ for n, etc
	    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
	    var to   = "aaaaeeeeiiiioooouuuunc------";
	    for (var i=0, l=from.length ; i<l ; i++) {
	    	str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	    }

	    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
	        .replace(/\s+/g, '-') // collapse whitespace and replace by -
	        .replace(/-+/g, '-'); // collapse dashes

	        return str;
    },

	formatCreatedImage: function(imageName, upload_timestamp, file, upload_type = 1) {

		var array = imageName.split(".");
		var extension = array[array.length-1];
		array.pop();

		var unique_id = Math.floor(Math.random() * 1000000000) + 10000;
		var newFileTitle = array.join('.') + '-' + unique_id; 
		var newFileName = this.string_to_slug(newFileTitle) + '.' + extension;
		var fileUrl = '/images/' + newFileName;

		var image =  {
			title: newFileName,
			newFileName: newFileTitle,
			extension: extension,
			unique_id: unique_id,
			upload_timestamp: upload_timestamp,
			fileUrl: fileUrl,
			file: file,
			upload_type: upload_type,
			created_at: moment().utc().format('YYYY-MM-DD HH:mm:ss')
		}

		return image;

	},

	formatCreatedAddOn: function( createdAddOn ) {
		
		var unique_id = ( createdAddOn.unique_id ) ? createdAddOn.unique_id : Math.floor(Math.random() * 1000000000) + 10000;
		var created_at = ( createdAddOn.unique_id ) ? createdAddOn.created_at : moment().utc().format('yyyy-mm-dd hh:mm:ss');

		var addOn = {
			created_at: created_at,
			featured_image: createdAddOn.featured_image,
			image_unique_id: createdAddOn.image_unique_id,
			settings_unique_id: createdAddOn.settings_unique_id,
			title: createdAddOn.title,
			unique_id: unique_id
		}

		return addOn;

	},

	formatModelDropdown: function(rows) {
		
		var data = [];
		for(var i in rows) {
			var row = {
				value: rows[i].unique_id,
				label: rows[i].title
			}
			data.push(row);
		}

		return data;

	},

	formatBrandDropdown: function(brands) {
		
		var data = [];
		for(var i in brands) {
			var row = {
				value: brands[i],
				label: brands[i]
			}
			data.push(row);
		}

		return data;

	},

	formatColourDropDown: function(colours, defaults) {
		
		var data = [];
		for(var i in colours) {
			var colour = colours[i];
			var row = ( defaults ) ? colour.unique_id : { value: colour.unique_id, label: colour.title };
			data.push(row);
		}
		
		return data;

	},

	formatShirtDropDown: function(shirts, defaults) {
		
		var data = [];
		var firstRow = {
			value: 'unattached',
			label: 'Unattached'
		}
		data.push( firstRow );
		for(var i in shirts) {
			var shirt = shirts[i];
			var row = ( defaults ) ? shirt.unique_id : { value: shirt.unique_id, label: shirt.title };
			data.push(row);
		}
		return data;

	},

	formatCategoryDropDown: function(categories, defaults) {
		
		var data = [];
		for(var i in categories) {
			var category = categories[i];
			var row = ( defaults ) ? category.unique_id : { value: category.unique_id, label: category.title };
			data.push(row);
		}
		
		return data;

	},

	getColourIds: function(colours) {
		var data = [];
		for(var i in colours) {
			if( typeof colours[i].unique_id === 'undefined' ) {
				var row = colours[i];
			}
			else {
				var colour = colours[i];
				var row = colour.unique_id;
			}	
			data.push(row);
		}
		return data;
	},

	getSelectedValues: function(options) {

		var array = [];
		for(var i in options) {
			var option = options[i]; 
			if( option.selected ) {
				array.push(option.value);
			}
		}

		return array;

	}

}




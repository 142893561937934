import React from 'react'
import { render } from 'react-dom'

import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
import AppWebAPIUtilities from '../utils/AppWebAPIUtilities'
import AppUtilities from '../utils/AppUtilities'

module.exports = {
	
	filterShirts: function(unique_id) {
		AppDispatcher.dispatch({
			actionType: AppConstants.FILTER_SHIRTS,
			data: unique_id
		});
	},

	searchShirts: function(filterSearchTerm) {
		AppDispatcher.dispatch({
			actionType: AppConstants.SEARCH_SHIRTS,
			data: filterSearchTerm
		});
	},

	createShirt: function( shirt ) {

		var data = AppUtilities.formatCreatedShirt(shirt);
		data.modelAction = 'store';
		console.log('data', data);
		// return;

		// send to front end
		AppDispatcher.dispatch({
			actionType: AppConstants.CREATE_SHIRT,
			data: data
		});

		// Send to Utils to insert into database
		AppWebAPIUtilities.handleShirtData(data);

	},

	updateShirt: function( shirt ) {

		var data = AppUtilities.formatCreatedShirt(shirt);
		data.modelAction = 'update';

		AppDispatcher.dispatch({
			actionType: AppConstants.UPDATE_SHIRT,
			data: data
		});
		
		AppWebAPIUtilities.handleShirtData(data);

	},

	deleteShirt: function( unique_id ) {

		var data = {
			unique_id: unique_id, 
			modelAction: 'destroy'
		}
		// send to front end
		AppDispatcher.dispatch({
			actionType: AppConstants.DELETE_SHIRT,
			data: data
		});

		// Send to Utils to insert into database
		AppWebAPIUtilities.handleShirtData(data);

	},

};

import React from 'react'
import { render } from 'react-dom'
export default class PreviewPane extends React.Component {
	
	render = () => {
		return (

			<div className="wrapper">
				<a href="#" className="close-button" onClick={this.props.handleClick}>
					<div className="in">
					<div className="close-button-block"></div>
					<div className="close-button-block"></div>
					</div>
					<div className="out">
					<div className="close-button-block"></div>
					<div className="close-button-block"></div>
					</div>
				</a>
			</div>

		)
	}
}
/**
* Component Namespace: App/PanelMain/PanelArtwork
*/

import React from 'react'
import { render } from 'react-dom'

import Select from 'react-select';

/*libraries*/
import classnames from 'classnames'

const quantityOptions =  [
	{ value: '0', label: '0' }, { value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' }, { value: '7', label: '7' }, { value: '8', label: '8' }, { value: '9', label: '9' },
]

export default class SectionColourCount extends React.Component {

	constructor() {
		super();
		this.state = {
			sideColours: {},
		}
	}

	componentWillReceiveProps = () => {

		this.setState({
			sideColours: this.props.sideColours
		});

	}
	
	handleSideColourChange = (side, selectedOption) => {
		this.props.handleColourCountChange(side, selectedOption);
	}

	render = () => {

		return (
			
			<div className="section-artwork-addons section-colour-count">

				<div className="section-artwork-addons-inner section-colour-count-inner">
					<div className="colour-count-columns">
						<div className="colour-count-column column-front app-form-control">
							
							<div className="colour-count-input-outer">

								<Select
								  name="colours-front"
								  value={this.props.sideColours.front.quantity}
								  options={quantityOptions}
								  onChange={this.handleSideColourChange.bind(null, 'front')}
								  searchable={false}
								/>

							</div>
							<label htmlFor="colours-front">Colours on the front.</label>
						</div>
						<div className="colour-count-column column-back app-form-control">
							
							<div className="colour-count-input-outer">
								<Select
								  name="colours-back"
								  value={this.props.sideColours.back.quantity}
								  options={quantityOptions}
								  onChange={this.handleSideColourChange.bind(null, 'back')}
								  searchable={false}
								/>
							</div>
							<label htmlFor="colours-back">Colours on the back.</label>
						</div>
					</div>
				</div>

			</div>
			
		)

	}

}
import moment from 'moment'
import AppHelpers from './AppHelpers'

import PricingHelpers from './PricingHelpers'

module.exports = {

	getSizesQuantityTotal: function( sizes ) {
		var total = 0;
		for(var i in sizes) {
			total += sizes[i];
		}
		return total;
	},

	toQueryString: function (obj) {
		var parts = [];
		for (var i in obj) {
			if (obj.hasOwnProperty(i)) {
				parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
			}
		}
		return parts.join("&");
	},

	getQueryStringValues: function( data ) {

		var queryStringValues = {
			quantity: data.quantity,
			selectedShirt: data.selectedShirt.unique_id + '|' + data.selectedShirt.slug,
			selectedColour: data.selectedColour.unique_id + '|' + data.selectedColour.title,
			selectedSizes: this.buildSizeQueryString(data.selectedSizes),
			selectedAddOns: this.buildSelectedAddOnsQueryString(data.selectedAddOns)
		}

		return queryStringValues;

	},

	getQueryString: function( data ) {

		var queryStringValues = this.getQueryStringValues( data );

		var queryString = "?";
		queryString += `quantity=${data.quantity}`;
		queryString += data.selectedShirt ? `shirt=${queryStringValues.selectedShirt}&` : '';
		queryString += data.selectedColour ? `colour=${queryStringValues.selectedColour}&` : '';
		queryString += data.selectedSizes ? `sizes=${queryStringValues.selectedSizes}&` : '';
		queryString += data.selectedAddOns ? `addOns=${queryStringValues.selectedAddOns}` : '';

		return queryString;

	},

	buildSizeQueryString: function( sizes ) {
		return '2|3|32|55|32|22';
	},

	buildSelectedAddOnsQueryString: function( selectedAddOnsQueryString ) {
		return 'addOns[]=what&addOns[]=hello';
	},

	numberWithCommas :function(number) {
		
		var string = String(number);

		var arr = string.split('.');
		var addition = '';

		if( arr.length > 1 ) {
			addition = ( arr[1].length == 1 ) ? '0' : '';
		}
		else {
			addition = '.00';
		}

		var price = ( string + addition ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		return price;

	},

	getPriceFromString: function(number) {
		return (number > 0 ) ? '$' + this.numberWithCommas(number) : '-';
	},

	roundSize: function( quantity, ratio ) {
		return  parseFloat( Math.round( parseFloat(quantity) * ratio ) )
	},

	calculateSizeRatios: function(quantity) {

		var quantity = parseFloat(quantity);

		var sizes = {
			extraSmall: this.roundSize(quantity, 0.04),
			small: this.roundSize(quantity, 0.12),
			medium: this.roundSize(quantity, 0.31),
			large: this.roundSize(quantity, 0.31),
			extraLarge: this.roundSize(quantity, 0.16),
			twoExtraLarge: this.roundSize(quantity, 0.04),
			threeExtraLarge: this.roundSize(quantity, 0.02),
		}

		return sizes;
		
	},

	formatSelectedColour: function( selectedColour ) {

		var colour = {
			hex: selectedColour.hex,
			selected_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
			title: selectedColour.title,
			unique_id: selectedColour.unique_id,
			white_base_price: selectedColour.white_base_price,
			image_front: selectedColour.image_front,
			image_back: selectedColour.image_back,
		}

		return colour;

	},

	formatCreatedQuote: function( createdQuote ) {

		console.log(createdQuote);

		var unique_id = ( createdQuote.unique_id ) ? createdQuote.unique_id : Math.floor(Math.random() * 10000000) + 10000;
		var quote = {
			customer_quote_title: createdQuote.customer_quote_title,
			customer_name: createdQuote.customer_name,
			customer_email: createdQuote.customer_email,
			customer_message: createdQuote.customer_message,
			cartQuotes: this.formatSubmittedQuotes(createdQuote.cartQuotes),
			unique_id: unique_id,
			cartTotals: createdQuote.cartTotals
		}

		console.log(quote);

		return quote;

	},

	formatSubmittedQuotes: function( cartQuotes ) {
		var quotes = [];
		for(var i in cartQuotes) {
			var quote = cartQuotes[i];
			var quoteRow = {
				a_new_quote_row: 'oh ya',

				sideColours: quote.sideColours,
				coloursFront: quote.sideColours.front,
				coloursBack: quote.sideColours.back,
				uploadedArtwork: quote.uploadedArtwork,

				addOnsTotals: quote.addOnsTotals,
				unique_id: quote.unique_id,
				selectedShirt: quote.selectedShirt,
				quoteTotalPrice: quote.quoteTotalPrice,
				printSetupPriceTotals: quote.printSetupPriceTotals,
				selectedSizes: quote.selectedSizes,
				selectedColour: this.formatCartQuoteColour(quote.selectedColour),
				addOns: this.formatCartQuoteAddOns(quote.selectedAddOns),

				shirtTotalQuantity: quote.shirtTotalQuantity,
				shirtPricing: quote.shirtPricing

			}
			
			quotes.push(quoteRow);
			
		}
		return quotes;
	},

	formatCartQuoteColour: function( selectedColour ) {
		
			var colour = {
				a_formatted_colour: 'formatted colour',
				title: selectedColour.title,
				unique_id: selectedColour.unique_id,
				basePrice: selectedColour.basePrice,
				hex: selectedColour.hex,
				totalShirtColourPrice: selectedColour.totalShirtColourPrice,
				image_front: selectedColour.image_front,
				image_back: selectedColour.image_back,
				addOnsPricePerShirt: selectedColour.addOnsPricePerShirt,
				selectedAddOns: selectedColour.selectedAddOns,
			}

		return colour;

	},

	formatShirtColourSizes: function( colourSizes ) {

		var sizes = {
			'Extra Small': colourSizes.extraSmall,
			'Small': colourSizes.small,
			'Medium': colourSizes.medium,
			'Large': colourSizes.large,
			'Extra Large': colourSizes.extraLarge,
			'2 Extra Large': colourSizes.twoExtraLarge,
			'3 Extra Large': colourSizes.threeExtraLarge,
		}

		return sizes;

	},

	formatCartQuoteAddOns: function( selectedAddOns ) {

		var addOns = [];

		for(var i in selectedAddOns) {
			var addOnRow = selectedAddOns[i];
			var addOn = {
				title: addOnRow.title,
				price: addOnRow.price,
				unique_id: addOnRow.unique_id,
				
				pricePerShirt: addOnRow.quotePricing.pricePerShirt,
				setupFee: addOnRow.quotePricing.setupFee,
				addOnTotalPrice: addOnRow.quotePricing.addOnTotalPrice,
				setupFeePerShirt: addOnRow.quotePricing.setupFeePerShirt,
				addOnTotalPricePerShirt: addOnRow.quotePricing.addOnTotalPricePerShirt,
			}
			addOns.push(addOn);
		}

		return addOns;

	}

}








/**
 * Component Namespace: App/PanelMain
 */

import React from 'react'
import { render } from 'react-dom'
import { Router, Route, hashHistory } from 'react-router'

export default class PanelMain extends React.Component {
	render() {
		return (
			<div className="panel-main">
				<h1>Panel Main</h1>
			</div>
		)
	}
};
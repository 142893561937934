import React from 'react'
import { render } from 'react-dom'

import classnames from 'classnames'

export default class ColourGrid extends React.Component {

	isColourSelected = ( colour_unique_id ) => {

		if( this.props.selectedColour.unique_id == colour_unique_id.unique_id )
			return true;

	}

	getColourGrid = (colour, index) => {
		var isColourSelected = this.isColourSelected(colour);

		var thumb = ( colour.image_front ) ? <img src={colour.image_front.sizes.thumbnail} /> : <img src="/images/t-shirt-placeholder-19918-300x300.jpg"/>;

		var colourItemClasses = classnames({
			"card": true,
			"colour-grid-list-item": true,
			"colour-swatch--active": isColourSelected,
			"colour-swatch": true
		});

		var delay = index * 0.15;
		var delayStyle = delay + 's';

		var divStyle={
			animationDelay: delayStyle
		}

		return (
			<li key={index + colour.unique_id} className={colourItemClasses} style={divStyle}>
				<a href="#" onClick={this.props.selectColour.bind(null, colour.unique_id)} className="colour-swatch__link">
					<div className="colour-swatch__inner">
					
						<span className="colour-swatch__thumbnail-outer">{thumb}</span>
						<div className="colour-swatch__footer admin-card-footer">
							<h4 className="colour-swatch__title">{colour.title}</h4>
						</div>
					
					</div>
				</a>
			</li>
		);
		
	}

	render = () => {

		colourGrid = false;

		if(this.props.colours) {
			var colourGrid = this.props.colours.map( this.getColourGrid );
		}

		return (
			<div className="_card-list-outer">
				<ul className="card-list colour-swatch-list">
				{colourGrid}
				</ul>
			</div>
		);
	}

}
import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
import EventEmitterObject  from 'events'
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _imageStore = {
	images: {},
	filteredImages: [],
	filterSearchTerm: '',
	selectedShirtFilter: null
}

var _addImage = function(data) {

	for( var i in data ) {
		var image = data[i];
		if( !_imageStore.images[image.unique_id] ) {
			_imageStore.images[image.unique_id] = image;
		}
	}

}

var _imageMatchesShirtFilter = function( image ) {
	var imageColours = image.colours;
	if( !imageColours.length && _imageStore.selectedShirtFilter == 'unattached' ) {
		return true;
	}
	for( var i in imageColours ) {
		var imageColour = imageColours[i];
		for( var s in imageColour.shirts ) {
			if( imageColour.shirts[s].unique_id == _imageStore.selectedShirtFilter ) {
				return true;
			}
		}
	}
	return false;
}

var getAllImages = function() {

	var images = [];
	
	for(var i in _imageStore.images) {

		var push = true;
		
		// if the search term is set
		if( _imageStore.filterSearchTerm != '' ) {
			if( !_imageStore.images[i].title.toLowerCase().includes( _imageStore.filterSearchTerm.toLowerCase() ) ) {
				push = false;
			}
		}

		// if the shirt filter is set
		if( _imageStore.selectedShirtFilter ) {
			if( !_imageMatchesShirtFilter( _imageStore.images[i] ) ) {
				push = false;
			}
		}

		if( push ) {
			images.push(_imageStore.images[i]);
		}

	}

	images.sort(function(a, b) {
		if (b.created_at < a.created_at) {
			return -1;
		} 
		else if (b.created_at > a.created_at) {
			return 1;
		}
		return 0;
	});
	
	return images;

}

class ImageStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getAllImages(){
		return getAllImages();
	}

	getImage(unique_id) {
		return _imageStore.images[unique_id];
	}

};

const ImageStore = new ImageStoreClass();

AppDispatcher.register((payload) => {

	const action = payload;

	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_IMAGES:
			_addImage(action.data.data);
			ImageStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.CREATE_IMAGE:
			_imageStore.images[action.data.unique_id] = action.data;
			ImageStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_IMAGE:
			_imageStore.images[action.data.unique_id].title = action.data.title;
			ImageStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.SET_IMAGE_FILTER:
			_imageStore.filterSearchTerm = action.data.filterSearchTerm;
			_imageStore.selectedShirtFilter = action.data.selectedShirtFilter;
			ImageStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.DELETE_IMAGE:
			delete _imageStore.images[action.data.unique_id];
			ImageStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = ImageStore;

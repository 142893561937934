import React from 'react'
import ReactDOM from 'react-dom'
import { Router, hashHistory } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'

import '../sass/app/_app.scss';

/*stores*/
import QuoteStore from './stores/QuoteStore'

import AppWebAPIUtilities from './utils/AppWebAPIUtilities'

/*components*/
import App from './components/App';

AppWebAPIUtilities.getAllAppData();


export default class IndexComponent extends React.Component {

	render() {
		return (
			<BrowserRouter>
				<Route path="/" component={App}/>
			</BrowserRouter>
		)	
	}

};

ReactDOM.render(
  <IndexComponent />,
  document.getElementById('app')
);

import moment from 'moment'
import AppHelpers from './AppHelpers'

module.exports = {

	 /**
	  * Get total price per shirt
	  * selectedColour, sideColours, addOnsPricePerShirt
	  */
	 getPricePerShirt: function( activeQuote, shirt, markupStrategies ) {

	 	var selectedColour = activeQuote.selectedColour;
	 	var sideColours = activeQuote.sideColours;
	 	var addOnsPricePerShirt = activeQuote.addOnsPricePerShirt;

	 	var pricePerShirt = {
			printPricePerShirt: 0,
			totalPricePerShirt: 0,
			totalShirtPrice: 0,
			addOnsPricePerShirt: addOnsPricePerShirt,
			shirtBasePricing: {}
	 	}

	 	var shirtBasePricing = this.getShirtBasePricing( activeQuote, shirt, markupStrategies );

	 	pricePerShirt.shirtBasePricing = shirtBasePricing;

		var printPricePerShirt = sideColours.back.printColourPrice + sideColours.back.setupFee + sideColours.front.printColourPrice + sideColours.front.setupFee;
		
		var printPricePerShirtRounded = parseFloat(printPricePerShirt.toFixed(2));
		pricePerShirt.printPricePerShirt = printPricePerShirtRounded;
	 	
	 	if( shirt.unique_id == 4034669 ) {
	 		/*this number wasnt working*/
			// console.log(printPricePerShirtRounded);
		}	

		var totalPricePerShirt = shirtBasePricing.adjustedShirtBasePrice + printPricePerShirtRounded + addOnsPricePerShirt;
		var totalPricePerShirtRounded = parseFloat(totalPricePerShirt.toFixed(2));
		pricePerShirt.totalPricePerShirt = totalPricePerShirtRounded;

		
		var totalShirtPrice = totalPricePerShirtRounded * activeQuote.shirtTotalQuantity;
		var totalShirtColourPriceRounded = parseFloat(totalShirtPrice.toFixed(2));
		pricePerShirt.totalShirtPrice = totalShirtColourPriceRounded;

		return pricePerShirt;

	},

	/*shirt total base price*/
	getShirtBasePricing: function( activeQuote, shirt, markupStrategies ) {

		var selectedShirt = shirt;

		var shirtBasePricing = {
			shirtBasePrice: 0,
			adjustedShirtBasePrice: 0,
			shirtMarkupBreakPoints: {}
		}

		var sizes = activeQuote.selectedSizes;
		
		var white_base_price = activeQuote.selectedColour ? activeQuote.selectedColour.white_base_price : shirt.primaryColour.white_base_price;
		var markupStrategy = markupStrategies[selectedShirt.markup_strategy.unique_id];

		var markupLevels = markupStrategy.markup_levels; 
		var totalQuantity = AppHelpers.get_total_colour_quantities( sizes );
		var adjsutedShirtPrice, markupLevel, markupPoints;

		var shirtBasePrice = white_base_price ? selectedShirt.base_price_white : selectedShirt.base_price;

		shirtBasePricing.shirtBasePrice = parseFloat(shirtBasePrice);

		if( shirt.unique_id == 4034669 ) {
			// console.log(shirtBasePrice);
		}

		for(var i in markupLevels) {

			shirtBasePricing.shirtMarkupBreakPoints[ markupLevels[i].title ] = {
				price: markupLevels[i].markup_points * shirtBasePricing.shirtBasePrice,
				markupPoints: markupLevels[i].markup_points
			}

			if(totalQuantity >= markupLevels[i].qty_min && totalQuantity < markupLevels[i].qty_max ) {
				markupLevel = markupLevels[i];
			}

		}

		/*debug this*/
		markupPoints = markupLevel.markup_points;

		adjsutedShirtPrice = shirtBasePricing.shirtBasePrice * markupPoints;
		shirtBasePricing.adjustedShirtBasePrice = parseFloat(adjsutedShirtPrice.toFixed(2));

		return shirtBasePricing;

	},

	/*addon fees*/
	getAddonPricing: function(settings, shirtTotalQuantity = 200) {

		var settingsObject = JSON.parse(settings);
		var breakpoints = settingsObject.breakpoints;
		var price = 0;

		var setupFeePerShirt = settingsObject.setup / shirtTotalQuantity;	/*shirtTotalQuantity is not defined*/

		var data = {
			pricePerShirt: 0,
			setupFee: settingsObject.setup,
			addOnTotalPrice: 0,
			setupFeePerShirt: parseFloat(setupFeePerShirt.toFixed(2)),
			addOnTotalPricePerShirt: 0
		}

		for(var i in breakpoints) {
			if( shirtTotalQuantity >= breakpoints[i].min && shirtTotalQuantity > breakpoints[i].max ) {
				data.pricePerShirt = breakpoints[i].price;
			}
		}

		var addOnTotalPricePerShirt = parseFloat(data.pricePerShirt) + parseFloat(data.setupFeePerShirt);

		data.addOnTotalPricePerShirt = parseFloat(addOnTotalPricePerShirt.toFixed(2));
		data.addOnTotalPrice = data.pricePerShirt * shirtTotalQuantity;

		return data;
		

	},

	/*print setup fees*/
	getPrintSetupPrices: function(colourCount, shirtTotalQuantity, printSetupFeesObject) {


		var printSetupFees = JSON.parse(printSetupFeesObject.normal.settings_data);	// this should be dynamically set either normal or white
		// console.log('printSetupFees', printSetupFees);

		var selectedFeeRow = false;

		for(var i in printSetupFees) {
			var feeRow = printSetupFees[i];
			if( shirtTotalQuantity >= feeRow.min && shirtTotalQuantity <= feeRow.max ) {
				selectedFeeRow = feeRow;
			}
		}
		
		if(selectedFeeRow) {
			
			var printColourPrice = parseFloat(selectedFeeRow.colour_counts[colourCount].price);
			var setupFee = selectedFeeRow.colour_counts[colourCount].setup_fee / shirtTotalQuantity;
			var quantity = parseInt(colourCount);
			var printTotalPricePerShirt = printColourPrice + parseFloat(setupFee.toFixed(2));
			var printToalPrice = printTotalPricePerShirt * shirtTotalQuantity;

			var setupFeeData = {
				printColourPrice: printColourPrice,
				setupFee: parseFloat(setupFee.toFixed(2)),
				quantity: quantity,
				printTotalPricePerShirt: parseFloat(printTotalPricePerShirt.toFixed(2)),
				printToalPrice: parseFloat(printToalPrice.toFixed(2))
			}

		}

		else {
			var setupFeeData = {
				printColourPrice: 0,
				setupFee: 0,
				quantity: parseInt(colourCount)
			}
		}

		return setupFeeData;

	}

}













import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
// import objectAssign from 'react/lib/Object.assign'
import EventEmitterObject  from 'events'
// var EventEmitter = require('events').EventEmitter;
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _colourStore = {
	colours: {},
	filteredColours: []
}

var _addColours = function(data) {

	for( var i in data ) {
		var colour = data[i];
		if( !_colourStore.colours[colour.unique_id] ) {
			_colourStore.colours[colour.unique_id] = colour;
		}
	}

}

var getAllColours = function() {

	var colours = [];
	
	for(var i in _colourStore.colours) {
		colours.push(_colourStore.colours[i])
	}

	colours.sort(function(a, b) {
		if (b.created_at < a.created_at) {
			return -1;
		} 
		else if (b.created_at > a.created_at) {
			return 1;
		}
		return 0;
	});

	return colours;

}

// var ColourStore = objectAssign({}, EventEmitter.prototype, {
class ColourStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getAllColours(){
		return getAllColours();
	}

	getColour(unique_id){
		return _colourStore.colours[unique_id];
	}

};

const ColourStore = new ColourStoreClass();

AppDispatcher.register((payload) => {

	const action = payload;

	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_COLOURS:
			_addColours(action.data.data);
			ColourStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.CREATE_COLOUR:
			_colourStore.colours[action.data.unique_id] = action.data;
			ColourStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_COLOUR:
			_colourStore.colours[action.data.unique_id] = action.data;
			ColourStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.DELETE_COLOUR:
			delete _colourStore.colours[action.data.unique_id];
			ColourStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = ColourStore;

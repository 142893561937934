import React from 'react'
import { render } from 'react-dom'
export default class Loader extends React.Component {
	
	render = () => {
		
		if( !this.props.show ) {
			return <div></div>
		}

		return (

			<div className="loader">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>

		)
	}
}
/**
 * Component Namespace: App/Sidebar/SidebarNavigation
 */

import React from 'react'
import ReactDOM from 'react-dom'
// import { Link, NavLink } from 'react-router'
import { Router, Route, Link, NavLink, hashHistory } from 'react-router-dom'

/*stores*/
import QuoteStore from '../../stores/QuoteStore'

/*utilities*/
import AppIcons from '../../utils/AppIcons'

/*libraries*/
import classnames from 'classnames';

// export default React.createClass({
export default class SidebarNavigation extends React.Component {

	constructor() {
		super();
		this.state = {
			activeQuote: {}
		}
	}

	componentDidMount = () => {
		QuoteStore.addChangeListener(this._onChange);
		var quantity = QuoteStore.getTotalQuantity();
		this.setState({
			activeQuote: QuoteStore.getActiveQuote(),
		});
	}

	componentWillUnmount = () => {
		QuoteStore.removeChangeListener(this._onChange);
	}

	_onChange = () => {
		this.setState({
			activeQuote: QuoteStore.getActiveQuote(),
		});
	}

	render = ()=> {

		var quantity = this.state.activeQuote.shirtTotalQuantity;

		var shirtItemClasses = classnames({
			"navigation__link": true,
			"navigation__link--active": this.props.location.pathname == '/'
		});
		var customizeItemClasses = classnames({
			"navigation__link": true,
			"navigation__link--active": this.props.location.pathname == '/customize'
		});
		var submitItemClasses = classnames({
			"navigation__link": true,
			"navigation__link--active": this.props.location.pathname == '/submit'
		});

		return (
			<div className="navigation navigation--main main-navigation">
				<ul className="sidebar__navigation navigation__list">
					
					<li className="navigation__item navigation__item--shirts link-to-shirts">
						<a href="#" className={shirtItemClasses}>
							<div className="navigation__icon-outer nav-icon-outer">
								<AppIcons icon="shirtIcon" />
							</div>
							<span className="navigation__label">Select Product</span>
						</a>
					</li>
					
					<li className="navigation__item navigation__item--customize link-to-customize">
						<Link to={{pathname: '/customize', search: `?quantity=${quantity}`}} className={customizeItemClasses}>
							<div className="navigation__icon-outer nav-icon-outer">
								<AppIcons icon="drawIcon" />
							</div>
							<span className="navigation__label">Customize</span>
						</Link>
					</li>
					
					<li className="navigation__item navigation__item--submit link-to-submit">
						<Link to="/submit" className={submitItemClasses}>
							<div className="navigation__icon-outer nav-icon-outer">
								<AppIcons icon="cartIcon" />
							</div>
							<span className="navigation__label">Submit</span>
						</Link>
					</li>
					
				</ul>
			</div>
		)
	}
};
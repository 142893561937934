module.exports = {
	
	get_scrf_token : function() {
		var metaTags=document.getElementsByTagName("meta");

		var fbAppIdContent = "";
		for (var i = 0; i < metaTags.length; i++) {
		    if (metaTags[i].getAttribute("name") == "_token") {
		        var scrf_token = metaTags[i].getAttribute("content");
		        return scrf_token;
		        break;
		    }
		}
	},

	get_total_colour_quantities: function(colours) {
		var totalColours = 0;
		for(var i in colours) {
			totalColours += colours[i];
		}
		return totalColours;
	}

}



/**
 * Component Namespace: App/PanelMain/PanelColours
 */

import React from 'react'
import ReactDOM from 'react-dom'
// import { Router, Route, hashHistory } from 'react-router'
import { BrowserRouter as Router, Route, Redirect, withRouter } from 'react-router-dom'

/*stores*/
import QuoteStore from '../../../stores/QuoteStore'
import ColourStore from '../../../stores/ColourStore'
import AddOnStore from '../../../stores/AddOnStore'
import ShirtStore from '../../../stores/ShirtStore'

/*components*/
import ColourGrid from './ColourGrid'
import ColourQuantityForms from './ColourQuantityForms'
import SectionAddOns from './SectionAddOns'
import SectionColourCount from './SectionColourCount'


/*actions*/
import QuoteActionCreators from '../../../actions/QuoteActionCreators'
import AppDispatcher from '../../../dispatcher/AppDispatcher'
import AppConstants from '../../../constants/AppConstants'

/*libraries*/
import queryString from 'queryString'
import classnames from 'classnames'
import update from 'immutability-helper'

/*utilities*/
import QuoteUtilities from '../../../utils/QuoteUtilities'

class PanelCustomize extends React.Component {

	constructor() {
		super();
		this.state = {
			activeQuote: null,
			availableColours: null,
			selectedColour: null,

			/*new*/

			markupStrategies: {},
			printSetupFees: {},
			panelClass: '',
 			allAddOns: [],
 			selectedAddOns: [],
 			panelClass: ''
		}
	}

	componentDidMount = () => {

		window.scrollTo(0, 0);

		QuoteStore.addChangeListener(this._onQuoteStoreChange);

 		this.setQuoteStoreData();

	}

	componentWillUnmount = ()=> {

		QuoteStore.removeChangeListener(this._onQuoteStoreChange);

	}

	_onQuoteStoreChange = () => {
		this.setQuoteStoreData();
	}

	setQuoteStoreData = () => {
		this.setState({
			activeQuote: QuoteStore.getActiveQuote(),
			availableColours: QuoteStore.getShirtColours(),
			markupStrategies: QuoteStore.getMarkupStrategies(),
			selectedColour: QuoteStore.getSelectedColour(),
 			allAddOns: QuoteStore.getAllAddOns(),
 			selectedAddOns: QuoteStore.getSelectedAddOns(true),
		});
	}

	handleSelectAddOn = (addOn) => {
		AppDispatcher.dispatch({
			actionType: AppConstants.QUOTE_SELECT_ADDON,
			data: addOn
		});
	}

	handleColourCountChange = (side, selectedOption) => {
		var number = selectedOption.value;
		var data = {
			side: side,
			colourCount: number
		}

		AppDispatcher.dispatch({
			actionType: AppConstants.UPDATE_SIDE_COLOUR,
			data: data
		});
	}

	selectColour = (unique_id) => {
		QuoteActionCreators.selectColour( 
			ColourStore.getColour(unique_id), 
			this.state.markupStrategies, 
			this.state.activeQuote.selectedShirt, 
			this.state.activeQuote.shirtColoursTotalTemp,
			this.state.activeQuote.selectedSizes
		);
	}

	handleSelectColour = (unique_id, e) => {
		e.preventDefault();
		this.selectColour(unique_id);
	}

	getColourSizesTotal = (sizes) => {
		var total = 0;
		for(var i in sizes) {
			total += sizes[i];
		}
		return total;
	}

	handleUpdateQuoteColour = (data) => {

		hashHistory.push({
			pathname: '/customize',
			search: '?quantity=' + this.getColourSizesTotal(data.sizes)
		});

		QuoteActionCreators.updateColour(data, this.state.markupStrategies, this.state.activeQuote.selectedShirt);

	}

	handleQuoteRemoveColour = (unique_id) => {
		QuoteActionCreators.removeColour(unique_id);
	}

	handleNextPanel = (e) => {
		e.preventDefault();
		this.setState({panelClass: 'transitioning'});
		setTimeout(() => {
			this.props.history.push({
				pathname: '/submit'
			});
		}, 600);
	}

	handleSingleSizeUpdate = (size, value) => {
		
		var selectedSizes = this.state.activeQuote.selectedSizes;

		var newSelectedSizes = update(selectedSizes, {
			[size]: {$set: value}
		});

		this.setState({
			selectedSizes: newSelectedSizes
		});

		var data = {
			size: size,
			value: value
		}

		QuoteActionCreators.updateSelectedColourSize(data);

	}

	getShirtInfo = ()  => {

		if( !this.state.activeQuote.shirtPricing )
			return;

		return (
			<div className="shirt-info__inner">
			
				<div className="shirt-info__data">
					
					<div className="shirt-info__list-item shirt-info__brand">
						{this.state.activeQuote.selectedShirt.brand}
					</div>
						
				</div>
				
				<div className="shirt-info__description">
					<p>{this.state.activeQuote.selectedShirt.description}</p>
				</div>

			</div>
		)
	}

	getShirtTotalPrice = () => {
		return (
			<div className="shirt-info__pricing-total">
				<span className="shirt-colour-pricing-total shirt-info__price-per-shirt price-per-shirt">
					{QuoteUtilities.getPriceFromString(this.state.activeQuote.shirtPricing.totalPricePerShirt)}
				</span>
				<span className="shirt-info__pricing-total-label">/shirt</span>
			</div>
		);
	}

	render = () => {

		if( !this.state.activeQuote )
			return <div><h4>No selectedShirt Yet</h4></div>

		var appPanelClasses = classnames(
			'app-panel',
			'app-panel-colours',
			'colour-grid-outer',
			this.state.panelClass
		);

		var title = this.state.activeQuote ? <h2 className="panel-header__title">{this.state.activeQuote.selectedShirt.title}</h2> : '';
		var pricing = this.state.activeQuote ? this.getShirtInfo() : '';

		return (
			
			<div className={appPanelClasses}>

				<header className="preview-header panel-header panel-header--customize">
					<div className="panel-header__inner">
						{title}
					</div>
				</header>

				<div className="shirt-info section-customize section-customize--shirt-info">
					{pricing}
				</div>

				<div className="section-customize section-customize--colour-grid">

					<ColourGrid 
						colours={this.state.availableColours} 
						selectColour={this.handleSelectColour}
						selectedColour={this.state.selectedColour}
					/>

				</div>

				<div className="section-customize section-customize--colour-qty-print-count customize-form-box">

					<div className="section-row section-row--colour-qty-form">

						<ColourQuantityForms
							selectedSizes={this.state.activeQuote.selectedSizes}
							selectedColour={this.state.selectedColour}
							handleUpdateColour={this.handleUpdateQuoteColour}
							handleRemoveColour={this.handleQuoteRemoveColour}
							handleSingleSizeUpdate={this.handleSingleSizeUpdate}
						/>

					</div>

					<div className="section-row section-row--colour-count">

						<SectionColourCount
							// coloursFront={this.state.activeQuote.sideColours.coloursFront}
							// coloursBack={this.state.activeQuote.sideColours.coloursBack}
							sideColours={this.state.activeQuote.sideColours}
							handleColourCountChange={this.handleColourCountChange}
						/>

					</div>
				</div>

				<div className="section-customize section-customize--addons">

					<SectionAddOns
						allAddOns={this.state.allAddOns}
						selectAddOn={this.handleSelectAddOn}
						selectedAddOns={this.state.selectedAddOns}
						doParentThing={this.doParentThing}
					/>

				</div>


				<div className="section-customize next-panel to-submit">
					<div className="next-panel-inner">
						<a href="#" className="next-panel-link app-button purple" onClick={this.handleNextPanel}>Add to Quote!</a>
					</div>
				</div>
			</div>
			
		)
	}
}
export default withRouter(PanelCustomize);
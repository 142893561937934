import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
// import objectAssign from 'react/lib/Object.assign'
import EventEmitterObject  from 'events'
// var EventEmitter = require('events').EventEmitter;
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _shirt_store = {
	shirts: {},
	filteredShirts: [],
	categoryFilter: null,
	filterSearchTerm: '',
	alert_Message: '',
	serverResponse: {
		alertMessage: '',
		redirect: '',
		success: '',
		returnObject: {}
	}
}

var _addShirts = function(data) {

	for( var i in data ) {
		var shirt = data[i];
		if( !_shirt_store.shirts[shirt.unique_id] ) {
			shirt.colours = sortShirtColours(shirt.colours);
			_shirt_store.shirts[shirt.unique_id] = shirt;
		}
	}

}

var sortShirtColours = function(colours) {

	var sortedColours = [];
	var primaryIndex;
	var hasPrimary = false;
	for(var i in colours) {
		if( colours[i].primary == 1 ) {
			primaryIndex = i;
			hasPrimary = true;
			sortedColours.push(colours[i]);
		}
	}

	if(hasPrimary) {
		colours.splice(primaryIndex, 1);
	}

	var finalSortedColours = sortedColours.concat(colours);
	return finalSortedColours;

}

var getAllShirts = function() {

	var shirts = [];

	for(var i in _shirt_store.shirts) {

		var shirt = _shirt_store.shirts[i];
		var push = true;
		
		// if the search term is set
		if( _shirt_store.filterSearchTerm != '' ) {
			if( !_shirt_store.shirts[i].title.toLowerCase().includes( _shirt_store.filterSearchTerm.toLowerCase() ) ) {
				push = false;
			}
		}

		// if the shirt filter is set
		if( _shirt_store.categoryFilter ) {
			if( !shirt.product_category || _shirt_store.categoryFilter != shirt.product_category.unique_id ) {
				push = false;
			}
		}

		if( push ) {
			shirts.push(_shirt_store.shirts[i]);
		}

	}

	shirts.sort(function(a, b) {
		if (b.created_at < a.created_at) {
			return -1;
		} 
		else if (b.created_at > a.created_at) {
			return 1;
		}
		return 0;
	});

	return shirts;

}



var getDistinctBrands = function() {
	var brands = [];
	for( var i in _shirt_store.shirts ) {
		var brand = brands.indexOf(_shirt_store.shirts[i].brand);
		if(brand < 0) {
			brands.push(_shirt_store.shirts[i].brand);
		}
	}
	return brands;
}

// var ShirtStore = objectAssign({}, EventEmitter.prototype, {
class ShirtStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getAllShirts(){
		return getAllShirts();
	}

	getShirt(unique_id) {
		return _shirt_store.shirts[unique_id];
	}

	getDistinctBrands() {
		return getDistinctBrands();
	}

	getServerResponse() {
		return _shirt_store.serverResponse;
	}

	getTempUniqueId() {
		return _shirt_store.temp_unique_id;
	}

};

const ShirtStore = new ShirtStoreClass();

// ShirtStore.dispatchToken = AppDispatcher.register(function(action) {
AppDispatcher.register((payload) => {

	const action = payload;

	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_SHIRTS:
			_addShirts(action.data.data);
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.RECEIVE_ADDED_SHIRT:
			_shirt_store.serverResponse.alertMessage = `${action.data.returnObject.title} has been created created.`;
			_shirt_store.serverResponse.redirect = `shirt/${action.data.returnObject.unique_id}`;
			setTimeout(() => {
				_shirt_store.serverResponse = {
					alertMessage: '',
					success: '',
					redirect: '',
					returnObject: {}
				}	
				ShirtStore.emit(CHANGE_EVENT);
			},3000);
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.CREATE_SHIRT:
			_shirt_store.shirts[action.data.unique_id] = action.data;
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_SHIRT:
			_shirt_store.shirts[action.data.unique_id] = action.data;
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.RECEIVE_UPDATED_SHIRT:
			_shirt_store.serverResponse.success = action.data.success;
			_shirt_store.serverResponse.alertMessage = `${_shirt_store.shirts[action.data.returnObject.unique_id].title} has been successfully updated.`;
			setTimeout(() => {
				_shirt_store.serverResponse = {
					alertMessage: '',
					success: '',
					returnObject: {}
				}	
				ShirtStore.emit(CHANGE_EVENT);
			},3000);
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.DELETE_SHIRT:
			delete _shirt_store.shirts[action.data.unique_id];
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.SEARCH_SHIRTS:
			_shirt_store.filterSearchTerm = action.data;
			ShirtStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.FILTER_SHIRTS:
			if(_shirt_store.categoryFilter != action.data) {
				_shirt_store.categoryFilter = action.data;
			}
			else {
				_shirt_store.categoryFilter =  null;
			}
			ShirtStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = ShirtStore;

import React from 'react';
import ReactDOM from 'react-dom'
import { render } from 'react-dom'
import { Spring } from 'react-motion'

import classnames from 'classnames'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import QuoteUtilities from '../../../utils/QuoteUtilities'

class ShirtGrid extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			columnCount: 0,
			itemWidth: 0,
			itemHeight: 0,
			listHeight: 0,
			animationName: '',
			animationDuration: ''
		}

	}

	componentDidMount = () => {
		
		window.addEventListener('resize', this._handleWindowResize);
		this.setState({ columnCount: 20 });

		this._handleWindowResize();

	}

	componentWillReceiveProps = () => {
		this._handleWindowResize();
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this._handleWindowResize);
	}

	_handleWindowResize = () => {

		this.setColumnCount();

	}

	setColumnCount = () => {

		var windowWidth = window.innerWidth;
		var columnCount = 0;

		if( windowWidth <= 880 ) {
			columnCount = 3;
		}
		else if( windowWidth <= 1100 ) {
			columnCount = 4;
		}
		else if( windowWidth <= 1500 ) {
			columnCount = 5;
		}
		else {
			columnCount = 6;
		}

		var listWidth = window.innerWidth - 175;
		var itemWidth = listWidth / columnCount - 20;
		var itemHeight = itemWidth * 1.4517 + 5;

		if( this.state.columnCount != columnCount ) {
			this.setState({ 
				columnCount: columnCount,
			});
		}

		this.setState({
			itemWidth: itemWidth,
			itemHeight: itemHeight
		});

	}

	initialCardStyles = (delayStyle, translateLeft, top, height, cardGutter) => {

		return {
			width: this.state.itemWidth + 'px',
			transform: 'translate3d(calc('+translateLeft+'% + ' + cardGutter + 'px), '+top+'px, 0px)',
			height: this.state.itemHeight + 'px'
		}	
	}

	getAnimationDuration = (translateLeft) => {

		var countFromLeft = translateLeft * .004;
		var fromLeftPercentage = ( this.state.columnCount - countFromLeft ) / this.state.columnCount;
		var animationDuration = fromLeftPercentage * 1;
		return animationDuration;

	}

	selectedCardStyles = (animationName,  translateLeft, top, height, animationDuration, cardGutter) => {

		var fromLeft = this.props.selectedShirtPosition.left;
		var transform = `translate3d(calc(${translateLeft}% ${cardGutter}px), ${top}px, 0px)`;

		return {
			animationName: animationName,
			animationDuration: `${animationDuration}s`,
			width: this.state.itemWidth + 'px',
			transform: transform,
			height: this.state.itemHeight + 'px',
			zIndex: 200
		}

	}

	getKeyFrames = (fromLeft, unique_id, translateLeft, top, cardGutter) => {
		
		let styleSheet = document.styleSheets[0];

		let animationName = `setImageToTop-${unique_id}`;

		var width = this.state.itemWidth;

		var listObject = document.getElementById("shirt-grid-list");
		if( !listObject )
			return;
		
			var listRectObject = listObject.getBoundingClientRect();
		var listFromTop = listRectObject.top;

		var translateStart = `translate3d(calc(${translateLeft}% + ${cardGutter}px), ${top}px, 0)`;
		var fromTop = listFromTop * -1 + 60;

		var previewFromLeft = window.innerWidth - 500;
		var listFromLeft = listRectObject.left; 
		var previewFromListLeft = previewFromLeft - listFromLeft;
		
		var translateEnd = `translate3d(${previewFromListLeft}px, ${fromTop}px, 0)`;

		let keyframes =
			`@keyframes ${animationName} {
			0 {
				height: ${this.state.itemHeight}px;
				transform: ${translateStart};
				width: ${this.state.itemWidth}px;
				z-index: 20;
			}
			100% {
				width: 500px;
				height: calc(100vh);
				transform: ${translateEnd};
				z-index: 20;
			}
		}`;

		styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

		return animationName;

	}

	getShirtBg = (imgSrc, bgHeight) => {
		var formattedImgSrc = encodeURI(imgSrc);
		var bgStyle = {
			backgroundImage: `url(${formattedImgSrc})`,
		}
		return (
			<div className="shirt-card__bg" style={bgStyle}></div>
		);
	}

	selectShirt = (animationDuration, unique_id, e) => {
		e.preventDefault();
		this.props.selectShirt(animationDuration, unique_id );
	}

	getShirtItem = (shirt, index) => {

		
		var category = (shirt.product_category) ? <p>{shirt.product_category.title}</p> : '';

		var delay = index * 0.15;
		var delayStyle = delay + 's';

		var left = (index%this.state.columnCount);
		var translateLeft = 100 * left;

		var cardGutterOffset = (index%this.state.columnCount);
		var cardGutter = (cardGutterOffset) * 20;

		var cardTopOffset = Math.floor(index / this.state.columnCount) * 40;

		var top = Math.floor(index / this.state.columnCount) * this.state.itemHeight + cardTopOffset;


		var fromLeft = this.props.selectedShirtPosition.left;
		var style;
		var bgHeight;
		var isSelected = false;

		var animationDuration = this.getAnimationDuration(translateLeft);

		if( this.props.activeQuote.selectedShirt && this.props.activeQuote.selectedShirt.unique_id == shirt.unique_id ) {
			var animationName = this.getKeyFrames(fromLeft, shirt.unique_id, translateLeft, top, cardGutter);
			style = this.selectedCardStyles(animationName, translateLeft, top, this.state.itemHeight, animationDuration, cardGutter);
			bgHeight = 'calc(100% - 70px)';
			isSelected = true;
		}
		else {
			style = this.initialCardStyles(delayStyle, translateLeft, top, this.state.itemHeight, cardGutter);
			bgHeight = '100%';
		}

		var img = (shirt.primaryColour) ? this.getShirtBg(shirt.primaryColour.image_front.sizes.large, bgHeight) : <img src="/images/t-shirt-placeholder-19918-300x300.jpg"/>;

		var shirtCardClasses = classnames({
			'card': true,
			'shirt-grid-list-item': true,
			[shirt.unique_id]: true,
			'selected': isSelected,
			'shirt-card': true,
			'shird-card--selected': isSelected
		});

		return (
			<li 
				key={shirt.unique_id} 
				style={style}
				className={shirtCardClasses}
				id={shirt.unique_id}
			>
			
				<div className="shirt-card-outer">
					<a href="#" onClick={this.selectShirt.bind(null, animationDuration, shirt.unique_id)} className="shirt-card__link">
						<div className="shirt-card__inner">
							
							<div className="shirt-card__image-wrapper">
								<div className="shirt-card__overlay"><span className="shirt-card__add-to-quote">Add to quote</span></div>
								{img}
							</div>

							<div className="shirt-card__footer">
								<div className="shirt-card__footer-inner">
									<h4 className="shirt-card__title">{shirt.title}</h4>
									<p className="shirt-card__brand">{shirt.brand}</p>

									<div className="shirt-card__info">
										
										<div className="shirt-card__price-colours">
											<p className="shirt-card__pricing"><span className="shirt-card__price">{QuoteUtilities.getPriceFromString(shirt.adjustedPricing.totalPricePerShirt)}</span><span>/shirt</span></p>
											<p className="shirt-card__colours">{shirt.colours.length} Colours</p>
										</div>
											
									</div>

								</div>
							</div>
						
						</div>
					</a>	
				</div>
			</li>
		);
	}

	render = () => {
		
		if(!this.props.shirts) {
			return <div></div>
		}

		var items = this.props.shirts.map( this.getShirtItem );

		var listHeight = Math.ceil( this.props.shirts.length / this.state.columnCount ) * this.state.itemHeight;

		var listStyle = {
			height: listHeight + 'px'
		}

		return (
			<div className="shirt-grid-outer card-list-outer">
				<ul 
					className="shirt-grid-list card-list"
					style={listStyle}
					id="shirt-grid-list"
					ref={node => {
						if(node !== null) {
							this._containerTarget = node;
						}
					}}
				>
					<ReactCSSTransitionGroup transitionName="example" transitionEnterTimeout={700} transitionLeaveTimeout={700}>
						{items}
					</ReactCSSTransitionGroup>
					<li></li><li></li><li></li><li></li>
				</ul>
			</div>
		);
	}

// });
}

export default ShirtGrid;
/**
 * Component Namespace: App/Header
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { Router, hashHistory } from 'react-router'
import { Switch, Route } from 'react-router-dom'

import AppIcons from '../../utils/AppIcons'

import QuoteStore from '../../stores/QuoteStore'

import QuoteUtilities from '../../utils/QuoteUtilities'

// export default React.createClass({
export default class Header extends React.Component {

	 constructor() {
	 	super();
		this.state = {
			// currentRoutePath: '',
			activeQuote: {}
		}
	}

	componentDidMount = ()=> {
		/*backcheck this*/
		var activeQuote = QuoteStore.getActiveQuote();
		this.setState({
			activeQuote: activeQuote
		});
		
		QuoteStore.addChangeListener(this._onChange);
	}

	componentWillUnmount() {
		
		QuoteStore.removeChangeListener(this._onChange);

	}


	_onChange = ()=> {

		var activeQuote = QuoteStore.getActiveQuote();
		this.setState({
			activeQuote: activeQuote
		});

	}
	
	getRunningPrice = () => {

		if( !this.state.activeQuote.shirtPricing || !this.state.activeQuote.shirtPricing.totalPricePerShirt )
			return <div></div>
		
		return (
			<div className="main-header__pricing">
				<div className="main-header__pricing-inner">
					<span className="main-header__pricing-text">${QuoteUtilities.numberWithCommas(this.state.activeQuote.shirtPricing.totalPricePerShirt)}/shirt</span>
				</div>
			</div>
		)
	}

	render = ()=> {
		
		var pricingText = this.getRunningPrice();

		return (
			<header className="main-header">
				<div className="main-header__inner">
					
					<div className="main-header__column header-column--left">
					</div>

					<div className="main-header__column header-column--center">
						<div className="header-logo-outer">
							<a href="/?quantity=200">
								<h1>Source Screen Printing</h1>
							</a>
						</div>
					</div>

					<div className="main-header__column header-column--right">
						{pricingText}
					</div>

				</div>
			</header>
		)
	}
};
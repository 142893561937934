import QuoteUtilities from '../../utils/QuoteUtilities'
import PricingHelpers from '../../utils/PricingHelpers'
import update from 'immutability-helper'
/*
QUOTE
*/
var _get_quote_template = function() {
	return {
		unique_id: null,
		activeColourPreview: null,
		selectedShirt: null,
		selectedColours: {},
		selectedColour: null,

		selectedSizes: {},
		totalQuantity: 0,

		selectedAddOns: {},
		uploadedArtwork: {
			front: {},
			back: {}
		},
		sideColours: {
			front: {
				printColourPrice: 0,
				setupFee: 0,
				quantity: 1
			},
			back: {
				printColourPrice: 0,
				setupFee: 0,
				quantity: 0
			}
		},

		quoteTotalPrice: 0,
		addOnsTotals: 0,
		addOnsPricePerShirt: 0,
		shirtColoursTotalTemp: 0,

		shirtPricing: {
			addOnsPricePerShirt: 0,
			printPricePerShirt: 0,
			shirtBasePricing: {},
			totalPricePerShirt: 0,
			totalShirtColourPrice: 0,
		}

	}
}

var _getActiveQuoteID = function() {
	return Math.floor(Math.random() * 10000000) + 10000;
}

// not in use
var _selectShirtCache = function(quote_store, selected_shirt_unique_id) {
	quote_store.activeQuote.selectedShirtCache = selected_shirt_unique_id;
}

module.exports = {
	
	setQuoteTemplate: function( quote_store )  {
		quote_store.activeQuote = _get_quote_template();
		quote_store.activeQuote.unique_id = _getActiveQuoteID();
		return quote_store;
	},
	
	setDefaultValues: function(quote_store, data) {

		var rawShirts = quote_store.activeQuote.rawShirts;

		
		quote_store.activeQuote.selectedShirtCache = data.selectedShirt;	// _selectShirtCache( data.selectedShirt );

		quote_store.initialQuantity = data.quantity;
		quote_store.activeQuote.totalQuantity = data.quantity;
		var defaultSizes = QuoteUtilities.calculateSizeRatios(quote_store.initialQuantity);
		quote_store.activeQuote.selectedSizes = defaultSizes;

		return quote_store;

	},

	setQuoteQuantity: function(quote_store, quantity) {
		quote_store.initialQuantity = quantity;
		return quote_store;
	},

	setAdjustedShirts: function( quote_store ) {
		
		quote_store.adjustedAllShirts = [];

		for(var i in quote_store.rawShirts) {
			var shirt = quote_store.rawShirts[i];
			shirt.adjustedPricing = PricingHelpers.getPricePerShirt(quote_store.activeQuote, shirt,  quote_store.markupStrategies);
			quote_store.adjustedAllShirts.push( shirt );
		}

		return quote_store;

	},

	setRawShirts: function(quote_store, rawShirts) {
		
		quote_store.rawShirts = rawShirts;
		return quote_store;

	},

	setStoreMarkupStrategies: function( quote_store, markupStrategies ) {

		var storeMarkupStrategies = {};

		for(var i in markupStrategies ) {
			storeMarkupStrategies[markupStrategies[i].unique_id] = markupStrategies[i];
		}

		quote_store.markupStrategies = storeMarkupStrategies;

		return quote_store;

	},

	selectShirt: function(quote_store, selected_shirt_unique_id) {

		var selectedShirtArray = quote_store.rawShirts.filter( (el) => {
			return el.unique_id == selected_shirt_unique_id;
		});

		quote_store.activeQuote.selectedShirt = selectedShirtArray[0];


		return quote_store;

	},
	
	setStorePrintSetupFees: function( quote_store, fees ) {
		quote_store.printSetupFees = fees;
		return quote_store;
	},

	setActiveQuoteTotalsInfo: function( quote_store ) {

		this.setQuotePrintSetupFees( quote_store );	//	Using PricingHelpers.getPrintSetupPrices()
		this.setQuoteAddonFees( quote_store );	//	PricingHelpers.getQuoteAddonFees();

		if( quote_store.activeQuote.shirtTotalQuantity > 0 ) {

			var pricePerShirt = PricingHelpers.getPricePerShirt(quote_store.activeQuote, quote_store.activeQuote.selectedShirt,  quote_store.markupStrategies);

			var activeQuote = quote_store.activeQuote;

			var newActiveQuote = update(activeQuote, 
				{shirtPricing: {$merge: pricePerShirt}}
				);
			quote_store.activeQuote = newActiveQuote;

		}	

		return quote_store;

	},

	setQuotePrintSetupFees: function( quote_store ) {
		// console.log('quote_store.activeQuote', quote_store.activeQuote);
		var printSetupFeesFront = PricingHelpers.getPrintSetupPrices( quote_store.activeQuote.sideColours.front.quantity, quote_store.activeQuote.shirtTotalQuantity, quote_store.printSetupFees );
		var printSetupFeesBack = PricingHelpers.getPrintSetupPrices( quote_store.activeQuote.sideColours.back.quantity, quote_store.activeQuote.shirtTotalQuantity, quote_store.printSetupFees );

		quote_store.activeQuote.sideColours.front = printSetupFeesFront;
		quote_store.activeQuote.sideColours.back = printSetupFeesBack;

		return quote_store;

	},

	setQuoteAddonFees: function( quote_store ) {

		var addOns = quote_store.activeQuote.selectedAddOns;

		quote_store.activeQuote.addOnsPricePerShirt = 0;
		quote_store.activeQuote.addOnsTotals = 0;

		for(var a in addOns) {
			var addOnPricing = PricingHelpers.getAddonPricing(addOns[a].pricing.settings_data, quote_store.activeQuote.shirtTotalQuantity);

			quote_store.activeQuote.selectedAddOns[a].quotePricing = addOnPricing;
			quote_store.activeQuote.addOnsPricePerShirt += parseFloat(addOnPricing.pricePerShirt) + parseFloat(addOnPricing.setupFeePerShirt.toFixed(2));

		}

		return quote_store;

	},

	handleDefaultColours: function( quote_store ) {
		if( 
			quote_store.activeQuote.selectedShirt && 
			! quote_store.activeQuote.selectedColour &&
			quote_store.activeQuote.unique_id
			) {

			var primaryColour = quote_store.activeQuote.selectedShirt.colours.filter( (el) => {
				return el.primary == 1;
			});

		var data = QuoteUtilities.formatSelectedColour( primaryColour[0] );

		quote_store = this.addSelectedColour( quote_store, data );
		quote_store = this.setQuoteColourTotals( quote_store );
		quote_store = this.setActiveQuoteTotalsInfo( quote_store );

		}

		return quote_store;

	},

	addSelectedColour: function( quote_store, colour ) {

		var addedColour = colour;

		quote_store.activeQuote.activeColourPreview = colour;

		quote_store.activeQuote.selectedColour = colour;	

		quote_store = this.setQuoteColourTotals( quote_store );
		
		return quote_store;

	},

	setQuoteColourTotals: function( quote_store ) {
		
		var sizes = quote_store.activeQuote.selectedSizes;
		var totals = QuoteUtilities.getSizesQuantityTotal( sizes );
		quote_store.activeQuote.shirtTotalQuantity = totals;

		return quote_store;

	},

	initializeQuote: function( quote_store ) {
		

		var quote_template = _get_quote_template();
		var defaultSizes = QuoteUtilities.calculateSizeRatios(quote_store.initialQuantity);

		var new_quote_template = quote_template;

		new_quote_template.shirtColoursTotalTemp = parseInt(quote_store.initialQuantity);
		new_quote_template.selectedSizes = defaultSizes;

		quote_store.activeQuote = new_quote_template;

		quote_store = this.setQuoteColourTotals( quote_store );

		quote_store = this.setQuotePrintSetupFees( quote_store );

		return quote_store;
		
	},

	setActiveQuoteToCart: function( quote_store ) {

		var activeQuote = quote_store.activeQuote;
		quote_store.cartQuotes[ quote_store.activeQuote.unique_id ] = quote_store.activeQuote;

		return quote_store;

	},

	setCartTotalPrice: function( quote_store ) {
		
		var total = 0;

		if( Object.keys(quote_store.cartQuotes).length > 0 ) {
			for( var i in quote_store.cartQuotes ) {
				total += quote_store.cartQuotes[i].shirtPricing.totalShirtPrice;
			}
		}

		quote_store.cartTotalPrice = parseFloat(total.toFixed(2));

		return quote_store;

	},

	updateColourSize: function( quote_store, colour ) {

		var sizes = quote_store.activeQuote.selectedSizes;

		var sizeKey = colour.size;
		var sizeValue = parseInt(colour.value);

		var newSizes = update(sizes, {
			[sizeKey]: {$set: sizeValue}
		});

		quote_store.activeQuote.selectedSizes = newSizes;
		quote_store.activeQuote.shirtTotalQuantity = QuoteUtilities.getSizesQuantityTotal( newSizes );

		quote_store = this.setQuoteColourTotals( quote_store );

		return quote_store;

	},

	addSelectedAddOn: function( quote_store, addOn ) {

		if( quote_store.activeQuote.selectedAddOns.hasOwnProperty( addOn.unique_id ) ) {
			delete quote_store.activeQuote.selectedAddOns[addOn.unique_id];
		}
		else {
			quote_store.activeQuote.selectedAddOns[addOn.unique_id] = addOn;
		}

		return quote_store;

	},
	
	removeCartQuote: function( quote_store, unique_id ) {
		delete quote_store.cartQuotes[unique_id];
		return quote_store;
	},

	submitResetQuote: function( quote_store ) {
		quote_store.activeQuote = _get_quote_template();
		return quote_store;
	},

	udpateSideColourQuantities: function( quote_store, data ) {

		if(quote_store.activeQuote) {
			quote_store.activeQuote.sideColours[data.side].quantity = parseInt(data.colourCount);
		}
		return quote_store;

	},

	addQuoteToActiveQuote: function( quote_store, unique_id ) {
		
		var quotes = quote_store.cartQuotes;
		var quote = quotes[unique_id];
		delete quote_store.cartQuotes[unique_id];
		quote_store.activeQuote = quote;

		return quote_store;

	},

	setQuoteStoreAddOns: function( quote_store, allAddOns) {

		var quoteStoreAddOns = [];
		for(var i in allAddOns) {
			var addOnPricing = PricingHelpers.getAddonPricing( allAddOns[i].pricing.settings_data, quote_store.activeQuote.shirtTotalQuantity );
			allAddOns[i].adjustedPricing = addOnPricing;
			quoteStoreAddOns.push(allAddOns[i]);
		}
		quote_store.allAddOns = quoteStoreAddOns;

		return quote_store;

	}





}












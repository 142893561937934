/**
 * Component Namespace: App/PreviewPane/PreviewImage
 */

import React from 'react'
import { render } from 'react-dom'

import classnames from 'classnames'

import ArtworkUpload from './ArtworkUpload'

export default class PreviewPane extends React.Component {

	constructor() {
		super();
		this.state = {
 			front: {},
 			back: {},
 			// uploading: false,
 			panelClass: ''
		}
	}

	handleSideSwitch = (side, e) => {
		e.preventDefault();
		this.props.setActiveSide(side);
	}

	/*dropzone stuff*/
	handleSingleImageUpload = ( imageData ) => {

		var newState = this.state;
		newState[imageData.side] = imageData;

		this.setState(newState);
		this.props.handleArtworkUpload(imageData)

	}

	removeSideImage = (side, e) => {
		e.preventDefault();
		var newState = this.state;
		newState[side] = null;
		this.setState({newState});
	}

	handleUploadSuccess = (object) => {
		this.props.handleArtworkUpload(object)

	}

	getShirtPreview = () => {
		if(!this.props.selectedShirt)
			return;
		
		var imgFront, imgBack, imgFrontThumb, imgBackThumb;

		if( ( this.props.activeColourPreview ) && ( typeof this.props.activeColourPreview.image_front != 'undefined' ) ) {
			imgFront = this.props.activeColourPreview.image_front.sizes.large;
			imgBack = this.props.activeColourPreview.image_back.sizes.large;
			imgFrontThumb = this.props.activeColourPreview.image_front.sizes.thumbnail;
			imgBackThumb = this.props.activeColourPreview.image_back.sizes.thumbnail;
		}

		else if(this.props.selectedShirt.primaryColour) {
			imgFront = this.props.selectedShirt.primaryColour.image_front.sizes.large;
			imgBack = this.props.selectedShirt.primaryColour.image_back.sizes.large;
			imgFrontThumb = this.props.selectedShirt.primaryColour.image_front.sizes.thumbnail;
			imgBackThumb = this.props.selectedShirt.primaryColour.image_back.sizes.thumbnail;
		}
		
		else {
			imgFront = imgBack = imgFrontThumb = imgBackThumb = "/images/t-shirt-placeholder-19918-300x300.jpg";
		}

		var previewImage = ( 'front' == this.props.activeSide ) ? imgFront : imgBack;
		var formattedImgSrc = encodeURI(previewImage);
		var styles = {
			backgroundImage: 'url(' + formattedImgSrc + ')'
		}

		var frontBackNavClasses = classnames({
			"active-front": (this.props.activeSide == "front"),
			"active-back": (this.props.activeSide == "back"),
			"front-back-nav": true,
		});

		return (
			<div className="preview-image-inner" style={styles}>
				<div className={frontBackNavClasses}>
					<ul>
						<li><a href="#" className="thumbnail-link activate-front" onClick={this.handleSideSwitch.bind(null,'front')}><img src={imgFrontThumb} /></a></li>
						<li><a href="#" className="thumbnail-link activate-back" onClick={this.handleSideSwitch.bind(null, 'back')}><img src={imgBackThumb} /></a></li>
					</ul>
				</div>
			</div>
		)
	}

	getArtworkUpload = () => {

		return (
			<ArtworkUpload
				activeSide={this.props.activeSide}
				front={this.state.front}
				back={this.state.back}
				checkState={this.checkState}
				// uploading={this.state.uploading}
				handleUploadSuccess={this.handleUploadSuccess}
				removeSideImage={this.removeSideImage}
				handleSingleImageUpload={this.handleSingleImageUpload}
			/>
		)
	}

	render = () => {

		var img, artworkUpload;

		if( this.props.selectedShirt ) {
			img = this.getShirtPreview();
		}
		else {
			return <div>there is not a thing</div>
		}

		if( 'submit' != this.props.currentRoutePath )
			artworkUpload = this.getArtworkUpload();

		return (
			<div className="preview-image">
				{img}
				{artworkUpload}
			</div>
		)
	}
}
/**
 * Component Namespace: App/PreviewPane/PreviewCart
 */

import React from 'react'
import { render } from 'react-dom'

import QuoteUtilities from '../../utils/QuoteUtilities'

export default class PreviewPane extends React.Component {

	getColourInformation = ( colour, index ) => {

		var colourKey = 'preview-' + colour.unique_id;
		var total = QuoteUtilities.getSizesQuantityTotal( colour.sizes );

		return (

			<li className="preview-cart-item" key={colourKey}>

				<div className="preview-cart-item-inner">

					<span className="preview-cart-item-swatch" style={{backgroundColor: colour.hex}}></span>

					<div className="preview-cart-item-title">
						<h5>{colour.title}</h5>
						<span className="colour-total-price">${QuoteUtilities.numberWithCommas(colour.totalShirtColourPrice)}</span>
					</div>

					<div className="preview-cart-item-info">
				
						<ul className="preview-cart-info-list">
							<li><span className="preview-cart-item-value">${QuoteUtilities.numberWithCommas(colour.totalPricePerShirtColour)}</span>/shirt</li>
							<li>x<span className="preview-cart-item-total">{total}</span></li>
						</ul>
						
					</div>

				</div>
			</li>
			
		);

	}

	render = () => {
		
		var colourInformation;
		if( this.props.selectedColours ) {

			console.log(this.props);

			// var colourInformation = this.props.selectedColours.map( this.getColourInformation );
			var colourInformation = this.getColourInformation(this.props.selectedColours, 0);

			var colourSectionContent = (

				<div className="preview-image-inner">
					<h4 className="section-label">Your Colours</h4>
					<ul className="preview-colour-information">
						{colourInformation}
					</ul>
				</div>
				
			);
		}

		return (
			<div className="preview-cart">
				<div className="preview-cart-fixed">
					{colourSectionContent}
				</div>
			</div>
		)
	}
}
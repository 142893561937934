/**
 * Component Namespace: App/PanelMain/PanelSubmit
 */

import React from 'react'
import { render } from 'react-dom'
import { Router, Route, hashHistory, browserHistory, Link } from 'react-router'

/*actions*/
import QuoteActionCreators from '../../../actions/QuoteActionCreators'

/*libraries*/
import classnames from 'classnames'

export default class PanelQuoteConfirm extends React.Component {

	constructor() {
		super();
		this.state = {
			panelClass: ''
		}
	}

	componentDidMount = () => {
		
		// QuoteActionCreators.setCurrentRoutePath('submit');

	}

	render = () => {

		var appPanelClasses = classnames(
			this.state.panelClass,
			'app-panel',
			'app-panel-confirmation',
			'panel-section-confirmation',
		);

		return (

			<div className={appPanelClasses}>

				<div className="panel-confirmation-inner">
					<h1>Thank you for submitting your quote with Source Screen Printing</h1>
					<p>One of our representatives will be in touch with you shortly!</p>
				</div>

			</div>
		)
	}

}
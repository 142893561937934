/**
 * Component Namespace: App/PreviewPane
 */

import React from 'react'
import { render } from 'react-dom'
import { Router, Route, hashHistory } from 'react-router'

/*components*/
import PreviewCart from './PreviewCart'
import PreviewImage from './PreviewImage'

/*actions*/
import QuoteActionCreators from '../../actions/QuoteActionCreators'

/*stores*/
import QuoteStore from '../../stores/QuoteStore'

/*utilities*/
import QuoteUtilities from '../../utils/QuoteUtilities'

export default class PreviewPane extends React.Component {

	constructor() {
		super();
		this.state = {
			selectedShirt: null,
			selectedColours: [],
			activeQuote: {},
			selectedShirt: null,
			activeSide: 'front',
			activeColourPreview: {
				image_front: null,
				image_back: null
			}
		}
	}

	componentDidMount = () => {
		QuoteStore.addChangeListener(this._onChange);
		this.setState({
			selectedShirt: QuoteStore.getSelectedShirt(),
			selectedColours: QuoteStore.getSelectedColour(),
			activeQuote: QuoteStore.getActiveQuote(),
			activeColourPreview: QuoteStore.getActiveColourPreview(),
		});
		
	}

	componentWillUnmount = () => {
		QuoteStore.removeChangeListener(this._onChange);
	}

	setActiveSide = (side)  => {
		this.setState({
			activeSide: side
		});
	}

	_onChange = ()  => {
		this.setState({
			selectedShirt: QuoteStore.getSelectedShirt(),
			selectedColours: QuoteStore.getSelectedColour(),
			activeQuote: QuoteStore.getActiveQuote(),
			activeColourPreview: QuoteStore.getActiveColourPreview(),
		});

	}

	perShirtPricing = ()  => {
		if( !this.state.activeQuote.selectedColour )
			return;

		return (
			<div className="shirt-colour-pricing">
				<ul>
					<li>
						{this.state.activeQuote.selectedColour.title}
					</li>
					<li>
						{this.state.activeQuote.selectedShirt.brand}
					</li>
					<li>
						<span className="shirt-colour-pricing-total">
							{QuoteUtilities.getPriceFromString(this.state.activeQuote.shirtPricing.totalPricePerShirt)}
						</span>
						<span className="shirt-colour-pricing-label">/shirt</span>
					</li>
				</ul>
				
			</div>
		)
	}

	handleArtworkUpload = (object)  => {
		object.side = this.state.activeSide;
		QuoteActionCreators.uploadArtwork(object);
	}

	render = () => {
		
		var title = this.state.selectedShirt ? <h2>{this.state.selectedShirt.title}</h2> : '';
		var pricing = ( this.state.selectedShirt ) ? this.perShirtPricing() : '';

		return (
			<div className="preview-pane app-column app-column-preview">
				<div className="app-column-inner">
					<PreviewImage 
						selectedShirt={this.state.selectedShirt}
						activeSide={this.state.activeSide}
						activeColourPreview={this.state.activeColourPreview}
						setActiveSide={this.setActiveSide}
						handleArtworkUpload={this.handleArtworkUpload}
					/>
				</div>
			</div>
		)
	}
}
import React from 'react'
import { render } from 'react-dom'

import update from 'immutability-helper'
import classnames from 'classnames'

export default class ColourQuantityForm extends React.Component {

	constructor() {
		super();
		this.state = {
			isFocused: false,
			focusedSize: '',
			transitionOutClass: '',
		}
	}

	handleQuantityChange = ( size, e ) => {

		this.props.handleSingleSizeUpdate(size, e.target.value);

	}

	handleOnFocus = (focusedSize) => {
		this.setState({focusedSize: focusedSize});
	}

	getForm = () => {
		if(!this.props.unique_id)
			return;

		var formClasses = classnames({
			"colour-qty-form-outer": true,
			"form-active": this.state.isFocused,
		});

		formClasses += ' ' + this.state.transitionOutClass;

		var inputClasses = {
			extraSmall: classnames({active: this.state.focusedSize == 'extraSmall', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.extraSmall > 0 }),
			small: classnames({active: this.state.focusedSize == 'small', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.small > 0 }),
			medium: classnames({active: this.state.focusedSize == 'medium', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.medium > 0 }),
			large: classnames({active: this.state.focusedSize == 'large', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.large > 0 }),
			extraLarge: classnames({active: this.state.focusedSize == 'extraLarge', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.extraLarge > 0 }),
			twoExtraLarge: classnames({active: this.state.focusedSize == 'twoExtraLarge', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.twoExtraLarge > 0 }),
			threeExtraLarge: classnames({active: this.state.focusedSize == 'threeExtraLarge', 'colour-qty-form__input-outer': true, hasValue: this.props.sizes.threeExtraLarge > 0 }),
		}

		return (

			<div className={formClasses}>

				<div className="colour-qty-form">
					<form 
						className="qty-form colour-qty-form-form" 
						onSubmit={this.handleFormSubmit}
					>
						<div className="colour-qty-form__form-row form-row size-input-row">
							
							<div className="colour-qty-form__input-column input-column app-form-control floating-input size-outer-xs">
								<div className={inputClasses.extraSmall}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-xs"
										value={this.props.sizes.extraSmall}
										onChange={this.handleQuantityChange.bind(this, 'extraSmall')}
										onFocus={this.handleOnFocus.bind(this, 'extraSmall')}
										name="size-xs"
									/>
									<label className="colour-qty-form__label" htmlFor="size-xs">XS</label>
								</div>
							</div>

							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-s">
								<div className={inputClasses.small}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-s" 
										value={this.props.sizes.small}
										onChange={this.handleQuantityChange.bind(this, 'small')}
										onFocus={this.handleOnFocus.bind(this, 'small')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-s">S</label>
								</div>
							</div>
							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-m">
								<div className={inputClasses.medium}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-m" 
										value={this.props.sizes.medium}
										onChange={this.handleQuantityChange.bind(this, 'medium')}
										onFocus={this.handleOnFocus.bind(this, 'medium')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-m">M</label>
								</div>
							</div>
							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-l">
								<div className={inputClasses.large}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-l" 
										value={this.props.sizes.large}
										onChange={this.handleQuantityChange.bind(this, 'large')}
										onFocus={this.handleOnFocus.bind(this, 'large')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-l">L</label>
								</div>
							</div>
							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-xl">
								<div className={inputClasses.extraLarge}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-xl" 
										value={this.props.sizes.extraLarge}
										onChange={this.handleQuantityChange.bind(this, 'extraLarge')}
										onFocus={this.handleOnFocus.bind(this, 'extraLarge')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-xl">XL</label>
								</div>
							</div>
							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-2xl">
								<div className={inputClasses.twoExtraLarge}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-3xl" 
										value={this.props.sizes.twoExtraLarge}
										onChange={this.handleQuantityChange.bind(this, 'twoExtraLarge')}
										onFocus={this.handleOnFocus.bind(this, 'twoExtraLarge')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-xl">2XL</label>
								</div>
							</div>
							<div className="colour-qty-form-form__input-column input-column app-form-control floating-input size-outer-3xl">
								<div className={inputClasses.threeExtraLarge}>
									<input 
										type="number"
										className="colour-qty-form__input colour-qty-form__input--number input--number"
										name="size-3xl" 
										value={this.props.sizes.threeExtraLarge}
										onChange={this.handleQuantityChange.bind(this, 'threeExtraLarge')}
										onFocus={this.handleOnFocus.bind(this, 'threeExtraLarge')}
									/>
									<label className="colour-qty-form__label" htmlFor="size-xl">3XL</label>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		);
		
	}

	render = () => {
		
		var form;

		if( this.props.unique_id ) {
			form = this.getForm();
		}
		
		return (
			<div className="colour-qty-form">
				{form}
			</div>
		)
	}

}
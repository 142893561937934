import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
// import objectAssign from 'react/lib/Object.assign'
import EventEmitterObject  from 'events'
// var EventEmitter = require('events').EventEmitter;
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _AddOnStore = {
	addOns: {}
}

var _addAddOns = function(data) {

	for( var i in data ) {
		var addOn = data[i];
		if( !_AddOnStore.addOns[addOn.unique_id] ) {
			_AddOnStore.addOns[addOn.unique_id] = addOn;
		}
	}

}

var getAllAddOns = function() {

	var addOns = [];
	
	for(var i in _AddOnStore.addOns) {
		addOns.push(_AddOnStore.addOns[i])
	}

	addOns.sort(function(a, b) {
		if (b.created_at < a.created_at) {
			return -1;
		} 
		else if (b.created_at > a.created_at) {
			return 1;
		}
		return 0;
	});
	
	return addOns;

}

// var AddOnStore = objectAssign({}, EventEmitter.prototype, {
class AddOnStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getAllAddOns(){
		return getAllAddOns();
	}

	getAddOn(unique_id){
		return _AddOnStore.addOns[unique_id];
	}

};

const AddOnStore = new AddOnStoreClass();

AppDispatcher.register((payload) => {

	const action = payload;
	
	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_ADDONS:
			_addAddOns(action.data.data);
			AddOnStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.CREATE_ADDON:
			_AddOnStore.addOns[action.data.unique_id] = action.data;
			AddOnStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_ADDON:
			_AddOnStore.addOns[action.data.unique_id] = action.data;
			AddOnStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.DELETE_ADDON:
			delete _AddOnStore.addOns[action.data.unique_id];
			AddOnStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = AddOnStore;

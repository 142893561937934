import AppDispatcher from '../dispatcher/AppDispatcher'
import AppConstants from '../constants/AppConstants'
// import objectAssign from 'react/lib/Object.assign'
import EventEmitterObject  from 'events'
// var EventEmitter = require('events').EventEmitter;
var EventEmitter = EventEmitterObject.EventEmitter;

var CHANGE_EVENT = 'change';

var _settings_store = {
	markupStrategies: {},
	printSetupFees: {}
}

var _addMarkupStrategies = function(data) {

	for( var i in data ) {

		var markupStategy = data[i];
		if( !_settings_store.markupStrategies[markupStategy.unique_id] ) {
			_settings_store.markupStrategies[markupStategy.unique_id] = markupStategy;
		}
	}

}

var _addPrintSetupFees = function(fees) {
	_settings_store.printSetupFees.normal = fees.data.normal;
	_settings_store.printSetupFees.white = fees.data.white;
}

var getAllMarkupStrategies = function() {

	var markupStrategies = [];
	
	for(var i in _settings_store.markupStrategies) {
		markupStrategies.push(_settings_store.markupStrategies[i])
	}

	return markupStrategies;

}

var _updateMarkupLevel = function(data) {
	console.log('_settings_store', _settings_store);
	console.log('data', data);
	_settings_store[data.unique_id] = data.markup_levels;
}

// var SettingsStore = objectAssign({}, EventEmitter.prototype, {
class SettingsStoreClass extends EventEmitter {

	addChangeListener(cb){
		this.on(CHANGE_EVENT, cb);
	}

	removeChangeListener(cb){
		this.removeListener(CHANGE_EVENT, cb);
	}

	getMarkupStrategies(){
		return getAllMarkupStrategies();
	}

	getMarkupStrategy(unique_id){
		return _settings_store.markupStrategies[unique_id];
	}

	getPrintSetupFees() {
		// console.log('_settings_store.printSetupFees', _settings_store.printSetupFees);
		return _settings_store.printSetupFees;
	}

};

const SettingsStore = new SettingsStoreClass();

AppDispatcher.register((payload) => {

	const action = payload;
	
	switch(action.actionType){
		case AppConstants.RECEIVE_RAW_MARKUP_STRATEGIES:
			_addMarkupStrategies(action.data.data);
			SettingsStore.emit(CHANGE_EVENT);
			break;
		case AppConstants.UPDATE_MARKUP_STRATEGY:
			SettingsStore.emit(CHANGE_EVENT);
			_updateMarkupLevel(action.data);
			break;
		case AppConstants.RECEIVE_RAW_PRINT_SETUP_FEES:
			_addPrintSetupFees(action.data);
			SettingsStore.emit(CHANGE_EVENT);
			break;
		default:
		return true;
	}
});

module.exports = SettingsStore;

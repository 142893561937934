import { Dispatcher } from 'flux';
const AppDispatcher = new Dispatcher();

AppDispatcher.handleAction = function(action){

	this.dispatch({
		source: 'CREATE_SHIRT',
		action: action
	});
	
};


module.exports = AppDispatcher;
/**
 * Component Namespace: App/PreviewPane/PreviewCart
*/

import React from 'react'
import { render } from 'react-dom'

/*components*/
import FormRowArtwork from './FormRowArtwork'

/*actions*/
import QuoteActionCreators from '../../../actions/QuoteActionCreators'
import AppDispatcher from '../../../dispatcher/AppDispatcher'
import AppConstants from '../../../constants/AppConstants'

/*libraries*/
import classnames from 'classnames';

export default class SectionQuoteNotes extends React.Component {

	constructor() {
		super();
		this.state = {
			upload: '',
			uploading: '',
			
			customer_quote_title: '',
			customer_name: '',
			customer_email: '',
			customer_message: '',
		}
	}

	handleQuoteSubmit = (e) => {
		e.preventDefault();
		this.props.submitForm(this.state);
	}

	/*
	Dropzone
	*/
	handleUploadSuccess = (object) => {

		AppDispatcher.dispatch({
			actionType: AppConstants.CREATE_IMAGE,
			data: object
		});

		this.setState({uploading: false});

	}

	removeFile = (side, e) => {
		e.preventDefault();
		var newState = this.state;
		newState[side] = null;
		this.setState({newState});
	}

	handleSingleImageUpload = ( imageData ) => {

		var newState = this.state;
		newState[imageData.side] = imageData;
		newState.uploading = imageData.side;

		this.setState(newState);
		QuoteActionCreators.uploadArtwork(imageData);

	}

	handleFieldChange = (fieldKey, e) => {
		this.setState({ [fieldKey]: e.target.value });
		this.props.handleFieldChange(fieldKey, e.target.value);
	}

	render = ()=> {

		var customer_quote_title_classes = classnames({
			'inputActive': this.state.customer_quote_title != '',
			'quoteNotesInput': true,
			'app-form-control': true,
			'floating-label': true
		});

		var customer_name_classes = classnames({
			'inputActive': this.state.customer_name != '',
			'quoteNotesInput': true
		});

		var customer_email_classes = classnames({
			'inputActive': this.state.customer_email != '',
			'quoteNotesInput': true
		});

		var customer_message_classes = classnames({
			'inputActive': this.state.customer_message != '',
			'quoteNotesInput': true
		});

		return (
			<div className="order-notes">
				<form onSubmit={this.handleQuoteSubmit}>
										
					<div className="order-note order-note-artwork">
						<div className="order-note-artwork-inner">
							<h4>Upload Design Artwork</h4>
							
							<div className="app-help-text">
								<p>Upload a file to help our designers create your custom shirt!</p>
							</div>

							<FormRowArtwork
								upload={this.state.upload}
								uploading={this.state.uploading}
								handleUploadSuccess={this.handleUploadSuccess}
								removeFile={this.removeFile}
								handleSingleImageUpload={this.handleSingleImageUpload}
							/>
						</div>
					</div>

					<div className="order-note order-note-title">
						<div className="container-narrow">
							<div className={customer_quote_title_classes}>
								{/*app-form-control floating-label*/}
								<input
									type="text"
									name="order-title"
									id="order-title"
									
									value={this.props.customer_quote_title}
									onChange={this.handleFieldChange.bind(null, 'customer_quote_title')}
								/>
								<label htmlFor="order-title">Name Your Quote</label>
								<span className="input-border-bottom"></span>
							</div>
						</div>
					</div>
					
					<div className="order-note order-note-artwork">
						<div className="order-note-inner container-narrow">
							<div className="order-notes-message">
								<div className="app-form-control-row">
									<div className="app-form-control submit-form-control-half floating-label">
										<input
											type="text"
											name="order-message-name"
											id="order-message-name"
											className={customer_name_classes}
											value={this.props.customer_name}
											onChange={this.handleFieldChange.bind(null, 'customer_name')}
										/>
										<label htmlFor="order-message-name">Your Name</label>
										<span className="input-border-bottom"></span>
									</div>
									<div className="app-form-control submit-form-control-half floating-label">
										<input
											type="text"
											name="order-message-email"
											id="order-message-email"
											className={customer_email_classes}
											value={this.props.customer_email}
											onChange={this.handleFieldChange.bind(null, 'customer_email')}
										/>
										<label htmlFor="order-message-email">Your Email</label>
										<span className="input-border-bottom"></span>
									</div>
								</div>
								<div className="app-form-control message-row">
									<div className="app-form-control submit-form-control floating-label">
										<textarea
											name="order-message-message"
											id="order-message-message"
											className={customer_message_classes}
											value={this.props.customer_message}
											onChange={this.handleFieldChange.bind(null, 'customer_message')}
										>
										</textarea>
										<label htmlFor="order-message-message">Your Message</label>
										<span className="input-border-bottom"></span>
									</div>
								</div>
								<div className="app-form-control submit-row">
									<input
										type="submit"
										onClick={this.handleQuoteSubmit}
										value="Submit Quote!"
									/>
								</div>
							</div>
						</div>
					</div>

				</form>	
			</div>
		)
	}
}